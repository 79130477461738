import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import tour1 from '../assets/images/tour01.jpg';
import tour2 from '../assets/images/tour02.jpg';
import tour3 from '../assets/images/tour03.jpg';
import tour4 from '../assets/images/tour04.jpg';
import tour5 from '../assets/images/tour05.jpg';
import tour6 from '../assets/images/tour06.jpg';
import tour7 from '../assets/images/tour07.jpg';
import tour8 from '../assets/images/tour08.jpg';
import tour9 from '../assets/images/tour09.jpg';
import tour10 from '../assets/images/tour10.jpg';
import tour11 from '../assets/images/tour11.jpg';
import tour11supp01 from '../assets/images/tour11supp01.png';
import tour12 from '../assets/images/tour12.jpg';
import tour13 from '../assets/images/tour13.jpg';

const sectionImages = {
    'Cultural Jeddah': tour1,
    'Cultural Jeddah (With Sobia)': tour2,
    'Tayyabat Museum & Enany Mosque': tour3,
    'Highlights of Jeddah': tour4,
    'Highlights of Jeddah with Airport drop off': tour5,
    'Lives of Saudi Women': tour6,
    'Jeddah Panoramic': tour7,
    'Beach Escape': tour8,
    'Bayadah Reef Experience (ALAMANA MARINE)': tour9,
    'Bayadah Reef Experience (REDSEA MARINE)': tour10,
    'Desert Camp Experience': tour11,
    'Cultural Jeddah (With Lunch)': tour12,
    'Cultural Jeddah (With Dinner)': tour13,
};


const ServiceProviderRFP = () => {
    const [sections, setSections] = useState(['t']);
    const [pricing, setPricing] = useState({});
    const [addonPricing, setAddonPricing] = useState({});
    const [tourCapacity, setTourCapacity] = useState({});
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme === 'dark' || (!savedTheme && window.matchMedia('(prefers-color-scheme: dark)').matches);
    });

    const [showNotePage, setShowNotePage] = useState(true);
    const [maximizedImage, setMaximizedImage] = useState(null);
    const [selectedSection, setSelectedSection] = useState(0); // Track the current section index
    const [otherTier, setOtherTier] = useState({});

    const [confirmation, setConfirmation] = useState(null);
    const [success, setSuccess] = useState(false);
    const [finished, setFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);

    const { port } = useParams();

    // Add this new state for tracking expanded ports
    const [expandedPorts, setExpandedPorts] = useState(new Set());

    // Update the state for bidding information
    const [isBiddingOpen, setIsBiddingOpen] = useState(false);
    const [biddingId, setBiddingId] = useState(null);
    const [cycleInfo, setCycleInfo] = useState(null);
    const [roundInfo, setRoundInfo] = useState(null);

    // Add a new state to track if bidding status check is complete
    const [isBiddingStatusChecked, setIsBiddingStatusChecked] = useState(false);

    // Add this new state near the other state declarations
    const [submittedData, setSubmittedData] = useState({});

    // Add this new state near the other state declarations
    const [isFetchingBidding, setIsFetchingBidding] = useState(false);

    // Add this new state for tracking ports with addons
    const [portsWithAddons, setPortsWithAddons] = useState({});

    // Add a new state for submitted addon pricing
    const [submittedAddonPricing, setSubmittedAddonPricing] = useState({});

    // Add state for file attachments
    const [fileAttachments, setFileAttachments] = useState({});
    const [fileUploadProgress, setFileUploadProgress] = useState({});

    // Add this helper function to group tours by port
    function groupToursByPort(tours) {
        return tours.reduce((acc, tour) => {
            if (!acc[tour.port]) {
                acc[tour.port] = [];
            }

            // Include all tours in the grouping, including addon sections
            acc[tour.port].push(tour);
            return acc;
        }, {});
    }

    // Add a new helper function to separate normal tours and addon tours by port
    function separateToursByType(tours) {
        return tours.reduce((acc, tour) => {
            if (!acc[tour.port]) {
                acc[tour.port] = { normal: [], addon: [] };
            }

            if (tour.type === 'Addon') {
                acc[tour.port].addon.push(tour);
            } else {
                acc[tour.port].normal.push(tour);
            }

            return acc;
        }, {});
    }

    // Modify the bidding status check useEffect
    useEffect(() => {
        const checkBiddingStatus = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/biddingStatus`, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch bidding status');
                }
                const data = await response.json();
                console.log('Bidding status:', data);

                const isOpen = data.status === 'Started';
                setIsBiddingOpen(isOpen);

                if (data.id) {
                    setBiddingId(data.id);
                }

                // Set cycle and round info
                if (data.cycleActive) {
                    setCycleInfo(data.cycleInfo);
                    setRoundInfo(data.round);
                } else {
                    setCycleInfo(null);
                    setRoundInfo(null);
                }

                if (!isOpen) {
                    setWarning('Bidding is currently closed. Please try again later.');
                }
            } catch (error) {
                console.error("Error checking bidding status:", error);
                setError(error);
            } finally {
                setLoading(false);
                setIsBiddingStatusChecked(true);
            }
        };

        checkBiddingStatus();
    }, []);

    // Modify the fetchRFPUserTours useEffect to separate normal and addon tours
    useEffect(() => {
        // Only proceed if bidding status has been checked
        if (!isBiddingStatusChecked) {
            return;
        }

        // If bidding is not open, don't fetch tours
        if (!isBiddingOpen) {
            return;
        }

        const fetchRFPUserTours = async () => {
            setLoading(true);
            try {
                const userEmail = JSON.parse(localStorage.getItem('user')).email;
                const userTours = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRFPUserTours/${userEmail}`);
                if (!userTours.ok) {
                    throw new Error('Failed to fetch lookups');
                }
                const userToursData = await userTours.json();

                if (userToursData.length === 0) {
                    setWarning('Nothing to submit. If this is a mistake, please contact your account manager.');
                    setLoading(false);
                    return;
                }

                if (userToursData.length > 0) {
                    localStorage.setItem('started', 'true');
                }

                const toursWithDetails = await Promise.all(userToursData.map(async userTour => {
                    const tourDetails = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRFPTour/${userTour.TourLookupId}`);
                    if (!tourDetails.ok) {
                        throw new Error('Failed to fetch lookups');
                    }
                    const details = await tourDetails.json();
                    return details;
                }));

                const tourTiers = await Promise.all(toursWithDetails.map(async tour => {
                    const tourTiers = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRDPTiers/${tour.id}`);
                    if (!tourTiers.ok) {
                        throw new Error('Failed to fetch lookups');
                    }
                    const tiers = await tourTiers.json();
                    return {
                        id: tour.id,
                        title: tour.Title,
                        port: tour.Port,
                        image: sectionImages[tour.Title],
                        tiers: tiers.sort((a, b) => a.Title.localeCompare(b.Title)).map(tier => ({ id: tier.id, title: tier.Title })),
                        season: tour.Season,
                        code: tour.Code,
                        document: tour.document,
                        type: tour.TourType || 'Normal' // Add tour type
                    };
                }));
                console.log(tourTiers);

                // Separate normal tours and addon tours
                const toursByType = separateToursByType(tourTiers);

                // Create addon sections by port
                const addonSections = Object.entries(toursByType).map(([port, { addon }]) => {
                    if (addon.length === 0) return null;

                    return {
                        id: `addon-${port}`,
                        title: `Additional Services - ${port}`,
                        port: port,
                        isAddonSection: true,
                        addonTours: addon,
                        code: `Additional Services` // Add a code property for display in the sidebar
                    };
                }).filter(Boolean);

                // Store ports with addons for reference
                const portsWithAddonsMap = {};
                addonSections.forEach(section => {
                    portsWithAddonsMap[section.port] = section;
                });
                setPortsWithAddons(portsWithAddonsMap);

                // Combine normal tours with addon sections
                const normalTours = tourTiers.filter(tour => tour.type !== 'Addon');
                const allSections = [...normalTours, ...addonSections];

                console.log('Normal tours:', normalTours);
                console.log('Addon sections:', addonSections);
                console.log('All sections:', allSections);

                setSections(allSections);
                setWarning(null);
            } catch (error) {
                console.error("Error fetching tours:", error);
                setError(error);
            }
            setLoading(false);
        };

        fetchRFPUserTours();
    }, [isBiddingStatusChecked, isBiddingOpen]); // Add dependencies

    useEffect(() => {
        document.documentElement.classList.toggle('dark', isDarkMode);
        localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    useEffect(() => {
        if (maximizedImage) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [maximizedImage]);

    useEffect(() => {
        if (selectedSection === sections.length) {
            if (sections.length > 1) {
                setFinished(true);
            }
            else {
                setWarning('Nothing to submit. If this is a mistake, please contact your account manager.');
            }
        }
        else {
            setFinished(false);
            setWarning(null);
        }
    }, [selectedSection, sections.length]);

    // Modify the fetchSubmittedData function in the useEffect
    useEffect(() => {
        const fetchSubmittedData = async () => {
            // Return early if no section id or bidding id
            if (!sections[selectedSection]?.id || !biddingId) {
                return;
            }

            setIsFetchingBidding(true); // Show loading spinner
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const userEmail = JSON.parse(localStorage.getItem('user')).email;
                const response = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getSubmittedBidding/${userEmail}/${sections[selectedSection].id}/${biddingId}`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    setSubmittedData(prev => ({
                        ...prev,
                        [sections[selectedSection].id]: data
                    }));

                    // Pre-fill the pricing state with submitted data
                    const pricingData = {};
                    data.tiers.forEach(tier => {
                        pricingData[tier.tierId] = tier.pricing;
                    });
                    setPricing(prev => ({
                        ...prev,
                        [sections[selectedSection].title]: pricingData
                    }));
                }
            } catch (error) {
                console.error("Error fetching submitted data:", error);
            } finally {
                setIsFetchingBidding(false); // Hide loading spinner
            }
        };

        if (isBiddingStatusChecked && biddingId) {
            fetchSubmittedData();
        }
    }, [selectedSection, sections, biddingId, isBiddingStatusChecked]);

    // Add a new useEffect to fetch submitted addon pricing data
    useEffect(() => {
        const fetchSubmittedAddonPricing = async () => {
            // Return early if no bidding id or if the current section is not an addon section
            if (!biddingId || !isBiddingStatusChecked || !sections[selectedSection]?.isAddonSection) {
                return;
            }

            setIsFetchingBidding(true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const userEmail = JSON.parse(localStorage.getItem('user')).email;
                const port = sections[selectedSection].port;
                const addonTours = sections[selectedSection].addonTours || [];

                // Fetch submitted data for each addon tour individually
                const addonPromises = addonTours.map(addon =>
                    fetch(
                        `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getSubmittedBidding/${userEmail}/${addon.id}/${biddingId}`,
                        {
                            headers: {
                                'Authorization': token,
                                'Content-Type': 'application/json'
                            }
                        }
                    ).then(res => res.ok ? res.json() : null)
                );

                const addonResults = await Promise.all(addonPromises);

                // Process the results
                const addonPricingData = {};
                const submittedAddons = [];

                addonResults.forEach((result, index) => {
                    if (result) {
                        const tourId = addonTours[index].id;
                        // Extract the price from the first tier if available
                        if (result.tiers && result.tiers.length > 0) {
                            const price = result.tiers[0].pricing?.price || '';
                            addonPricingData[tourId] = price;
                            submittedAddons.push({
                                tourId,
                                price
                            });
                        }
                    }
                });

                // Store the submitted addon pricing data
                setSubmittedAddonPricing(prev => ({
                    ...prev,
                    [port]: submittedAddons
                }));

                // Pre-fill the addon pricing state with submitted data
                setAddonPricing(prev => ({
                    ...prev,
                    [port]: addonPricingData
                }));

            } catch (error) {
                console.error("Error fetching submitted addon pricing:", error);
            } finally {
                setIsFetchingBidding(false);
            }
        };

        if (isBiddingStatusChecked && biddingId) {
            fetchSubmittedAddonPricing();
        }
    }, [selectedSection, sections, biddingId, isBiddingStatusChecked]);

    // Add a new useEffect to fetch submitted capacity data
    useEffect(() => {
        const fetchSubmittedCapacity = async () => {
            // Return early if no section id or bidding id
            if (!sections[selectedSection]?.id || !biddingId) {
                return;
            }

            setIsFetchingBidding(true); // Show loading spinner
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const userEmail = JSON.parse(localStorage.getItem('user')).email;
                const response = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getSubmittedBidding/${userEmail}/${sections[selectedSection].id}/${biddingId}`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (response.ok) {
                    const data = await response.json();

                    // Pre-fill the capacity state with submitted data if available
                    if (data.departures !== undefined && data.maxCapacity !== undefined) {
                        setTourCapacity(prev => ({
                            ...prev,
                            [sections[selectedSection].id]: {
                                departures: data.departures,
                                maxCapacity: data.maxCapacity
                            }
                        }));
                    }
                }
            } catch (error) {
                console.error("Error fetching submitted capacity data:", error);
            } finally {
                setIsFetchingBidding(false); // Hide loading spinner
            }
        };

        if (isBiddingStatusChecked && biddingId) {
            fetchSubmittedCapacity();
        }
    }, [selectedSection, sections, biddingId, isBiddingStatusChecked]);

    const handlePriceChange = (section, tier, type, value) => {
        setPricing(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [tier]: {
                    ...prev[section]?.[tier],
                    [type]: value
                }
            }
        }));
    };

    const handleOtherTierChange = (section, value) => {
        setOtherTier(prev => ({
            ...prev,
            [section]: value
        }));

        // Remove the 'Other' tier and add the new custom tier
        setPricing(prev => {
            const sectionPricing = { ...prev[section] };
            delete sectionPricing['Other'];
            return {
                ...prev,
                [section]: {
                    ...sectionPricing,
                    [value]: {
                        adultRate: '',
                        childRate: '',
                        infantRate: ''
                    }
                }
            };
        });
    };

    // Add a handler for addon pricing changes
    const handleAddonPriceChange = (port, tourId, value) => {
        setAddonPricing(prev => ({
            ...prev,
            [port]: {
                ...prev[port],
                [tourId]: value
            }
        }));
    };

    // Add a handler for file uploads
    const handleFileChange = (section, event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Validate file is Excel
        const validTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ];

        if (!validTypes.includes(file.type)) {
            setError(new Error('Only Excel files (.xls, .xlsx) are allowed'));
            event.target.value = '';
            return;
        }

        setFileAttachments(prev => ({
            ...prev,
            [section.id]: file
        }));
    };

    // Remove file attachment
    const handleRemoveFile = (sectionId) => {
        setFileAttachments(prev => {
            const updatedAttachments = { ...prev };
            delete updatedAttachments[sectionId];
            return updatedAttachments;
        });
    };

    // Add handler for capacity changes
    const handleCapacityChange = (sectionId, field, value) => {
        setTourCapacity(prev => ({
            ...prev,
            [sectionId]: {
                ...prev[sectionId],
                [field]: value
            }
        }));
    };

    // Modify the handleSubmit function to include capacity data
    const handleSubmit = async (event, section) => {
        event.preventDefault();

        if (!isBiddingOpen) {
            setError(new Error('Bidding is currently closed'));
            return;
        }

        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found in local storage');
            }
            const user = JSON.parse(localStorage.getItem('user'));

            // Handle addon section submission differently
            if (section.isAddonSection) {
                const addonTours = section.addonTours || [];
                const addonPricingData = addonPricing[section.port] || {};

                // Get list of already submitted tour IDs
                const submittedTourIds = (submittedAddonPricing[section.port] || [])
                    .map(item => item.tourId);

                // Prepare data for the new endpoint - only include tours that haven't been submitted yet
                const toursWithPricing = addonTours
                    .filter(addon =>
                        // Only include tours with prices that haven't been submitted yet
                        addonPricingData[addon.id] &&
                        !submittedTourIds.includes(addon.id)
                    )
                    .map(addon => ({
                        tourId: addon.id,
                        price: addonPricingData[addon.id],
                        tierId: addon.tiers && addon.tiers.length > 0 ? addon.tiers[0].id : 'default',
                        season: addon.season || 'All'
                    }));

                // Skip if no tours have pricing to submit
                if (toursWithPricing.length === 0) {
                    setLoading(false);
                    setSelectedSection(prev => prev + 1);
                    return;
                }

                // Use the new endpoint to submit all tours at once
                const result = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/submitAdditionalServices`, {
                    method: 'POST',
                    headers: {
                        'authorization': token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        port: section.port,
                        user: user,
                        tours: toursWithPricing,
                        biddingRound: biddingId,
                        cycleId: cycleInfo?.id || null,
                        roundNumber: roundInfo?.number || 1
                    })
                });

                if (!result.ok) {
                    throw new Error('Failed to submit Additional Services');
                }

                // Update the submittedAddonPricing state with the newly submitted tours
                const newlySubmittedTours = toursWithPricing.map(tour => ({
                    tourId: tour.tourId,
                    price: tour.price
                }));

                setSubmittedAddonPricing(prev => ({
                    ...prev,
                    [section.port]: [
                        ...(prev[section.port] || []),
                        ...newlySubmittedTours
                    ]
                }));
            } else {
                // Regular tour submission with file upload and capacity data
                const data = Object.entries(pricing[section.title] || {}).map(([tier, pricing]) => ({
                    tier,
                    pricing
                }));

                const formData = new FormData();

                // Get capacity data for this tour
                const sectionCapacity = tourCapacity[section.id] || { departures: "", maxCapacity: "" };

                // Add JSON data as a blob
                const reqData = {
                    tour: section.id,
                    pricing: data,
                    port: section.port,
                    user: user,
                    season: section.season,
                    biddingRound: biddingId,
                    cycleId: cycleInfo?.id || null,
                    roundNumber: roundInfo?.number || 1,
                    // Add the new capacity fields
                    departures: sectionCapacity.departures,
                    maxCapacity: sectionCapacity.maxCapacity
                };

                formData.append('data', JSON.stringify(reqData));

                // Add file if it exists
                if (fileAttachments[section.id]) {
                    const file = fileAttachments[section.id];
                    console.log(`Attaching file: ${file.name}, size: ${file.size}, type: ${file.type}`);
                    formData.append('file', file);
                }

                try {
                    // Create upload progress tracker
                    const uploadConfig = {
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            setFileUploadProgress(prev => ({
                                ...prev,
                                [section.id]: percentCompleted
                            }));
                        }
                    };

                    const result = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/submitRFP`, {
                        method: 'POST',
                        headers: {
                            'authorization': token,
                        },
                        body: formData
                    }, uploadConfig);

                    if (!result.ok) {
                        const errorText = await result.text();
                        console.error(`Server error: ${result.status} ${result.statusText}`, errorText);
                        throw new Error(`Server error: ${result.status} ${result.statusText}. ${errorText}`);
                    }
                } catch (uploadError) {
                    console.error("Upload failed:", uploadError);
                    throw new Error(`File upload failed: ${uploadError.message}`);
                }
            }

            setFileUploadProgress(prev => ({
                ...prev,
                [section.id]: 0
            }));

            setLoading(false);
            setSuccess(true);
            setSelectedSection(prev => prev + 1);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } catch (error) {
            console.error("Error submitting RFP:", error);
            setError(error);
            setLoading(false);
        }
    };

    const handleSkip = () => {
        setConfirmation({
            title: 'Are you sure you want to skip this tour?',
            message: 'You will not be able to make changes to this tour later.',
            onConfirm: () => {
                setSelectedSection(prev => prev + 1);
                setConfirmation(null);
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        });
    };

    const handleImageClick = (image) => {
        setMaximizedImage(image);
    };

    const handleCloseMaximizedImage = () => {
        setMaximizedImage(null);
    };

    // Update the UI to display cycle and round information
    // Update the bidding closed alert to show more information
    const BiddingStatusAlert = () => {
        if (!isBiddingStatusChecked) {
            return null;
        }

        if (!isBiddingOpen) {
            return (
                <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
                    <p className="font-bold">Bidding Closed</p>
                    <p>The bidding round is currently closed. You cannot submit pricing at this time.</p>
                </div>
            );
        }

        return (
            <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4" role="alert">
                <p className="font-bold">Bidding Open</p>
                {cycleInfo && (
                    <div>
                        <p>Current Cycle: {cycleInfo.title}</p>
                        {roundInfo && (
                            <p>Round {roundInfo.number} is active. Your submissions for this round will be recorded.</p>
                        )}
                    </div>
                )}
            </div>
        );
    };

    if (showNotePage) {
        return (
            <>
                {loading && (
                    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                    </div>
                )}
                {finished && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="finished-modal">
                        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                            <div className="mt-3 text-center">
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                    <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                    </svg>
                                </div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">All Forms Submitted!</h3>
                                <div className="mt-2 px-7 py-3">
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        You have successfully submitted all forms. You may now close this page.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {warning && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="warning-modal">
                        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                            <div className="mt-3 text-center">
                                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
                                    <svg className="h-6 w-6 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                    </svg>
                                </div>
                                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">Warning</h3>
                                <div className="mt-2 px-7 py-3">
                                    <p className="text-sm text-gray-500 dark:text-gray-400">
                                        {warning}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="max-w-2xl mx-auto p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md h-full">
                    <h1 className="text-3xl font-bold mb-6 text-center text-gray-900 dark:text-white">Important Note</h1>
                    <div className="space-y-4">
                        <p className="text-gray-700 dark:text-gray-300">
                            Please carefully review the following instructions before proceeding:
                        </p>
                        <ol className="list-decimal list-inside space-y-2 text-gray-700 dark:text-gray-300">
                            <li>Fill out all required fields in each form tour.</li>
                            <li>Double-check your entries for accuracy before submitting.</li>
                            <li>Submit each tour individually using the "Submit Pricing" button.</li>
                            <li>Once all tours are submitted, the link will be locked and you won't be able to make changes.</li>
                        </ol>
                        <p className="text-gray-700 dark:text-gray-300 font-semibold">
                            Please note: After submitting all forms, this link will be locked and you will not be able to make further changes.
                        </p>
                    </div>
                    <button
                        onClick={() => setShowNotePage(false)}
                        className="mt-8 w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
                    >
                        I Understand, Continue to Forms
                    </button>
                </div>
            </>
        );
    }

    return (
        <>
            {loading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                </div>
            )}
            {error && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="my-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                        <div className="mt-3 text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                                <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">Error</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    {error.message || "An error occurred. Please try again."}
                                </p>
                            </div>
                            <div className="items-center px-4 py-3">
                                <button
                                    id="ok-btn"
                                    className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                                    onClick={() => setError(null)}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {success && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="success-modal" style={{ zIndex: 51 }}>
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                        <div className="mt-3 text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">Success!</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    Your pricing has been successfully submitted.
                                </p>
                            </div>
                            <div className="items-center px-4 py-3">
                                <button
                                    id="ok-btn"
                                    className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                                    onClick={() => setSuccess(false)}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {finished && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="finished-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                        <div className="mt-3 text-center">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                            </div>
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">All Forms Submitted!</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    You have successfully submitted all forms. You may now close this page.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {confirmation && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="confirmation-modal">
                    <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                        <div className="mt-3 text-center">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">{confirmation.title}</h3>
                            <div className="mt-2 px-7 py-3">
                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                    {confirmation.message}
                                </p>
                            </div>
                            <div className="items-center px-4 py-3">
                                <button
                                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 mb-2"
                                    onClick={confirmation.onConfirm}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
                                    onClick={() => setConfirmation(null)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={`container mx-auto px-4 bg-white dark:bg-gray-800 text-gray-900 dark:text-white flex ${maximizedImage ? 'overflow-hidden' : ''}`}>
                {/* Index on the left side */}
                <div className="w-1/4 pr-4 pt-8 sticky top-0 h-screen overflow-auto">
                    <h2 className="text-2xl font-bold mb-4">Tours by Port</h2>
                    <ol className="relative border-l border-gray-200 dark:border-gray-700 ml-1">
                        {Object.entries(groupToursByPort(sections)).map(([port, portTours]) => (
                            <li key={port} className="mb-4 ml-4">
                                <div className="flex items-center">
                                    <button
                                        onClick={() => {
                                            setExpandedPorts(prev => {
                                                const newSet = new Set(prev);
                                                if (newSet.has(port)) {
                                                    newSet.delete(port);
                                                } else {
                                                    newSet.add(port);
                                                }
                                                return newSet;
                                            });
                                        }}
                                        className="flex items-center text-left hover:text-blue-500 transition duration-300 w-full"
                                    >
                                        <svg
                                            className={`w-4 h-4 mr-2 transform transition-transform ${expandedPorts.has(port) ? 'rotate-90' : ''
                                                }`}
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M9 5l7 7-7 7"
                                            />
                                        </svg>
                                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                            {port}
                                        </h3>
                                    </button>
                                </div>

                                {expandedPorts.has(port) && (
                                    <ul className="mt-2 ml-6">
                                        {/* Display all tours for this port */}
                                        {portTours.map((tour, index) => (
                                            <li key={`tour-${index}`} className="mb-2 relative">
                                                <div className={`absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-[1.67rem] border border-white dark:border-gray-900 dark:bg-gray-700 ${selectedSection === sections.indexOf(tour)
                                                    ? 'bg-blue-400 border-blue-500 dark:bg-blue-500 dark:border-blue-500'
                                                    : ''
                                                    }`}></div>
                                                <button
                                                    onClick={() => setSelectedSection(sections.indexOf(tour))}
                                                    className={`text-left hover:text-blue-500 transition duration-300 ${selectedSection === sections.indexOf(tour)
                                                        ? 'text-blue-500 font-semibold'
                                                        : ''
                                                        }`}
                                                >
                                                    <span className="text-sm">
                                                        {tour.isAddonSection ? "Additional Services" : tour.code}
                                                    </span>
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ol>
                </div>

                {/* Main content on the right side */}
                <div className="w-3/4 pl-4">
                    <h1 className="text-4xl font-bold text-center mb-8 pt-8">
                        {sections[selectedSection]?.port || port} Shore Excursion Details
                    </h1>
                    {isFetchingBidding && (
                        <div className="flex justify-center items-center my-8">
                            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                        </div>
                    )}

                    <BiddingStatusAlert />

                    {!isFetchingBidding && sections.map((section, index) => (
                        <form key={index}
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (!isBiddingOpen) {
                                    setError(new Error('Bidding is currently closed'));
                                    return;
                                }
                                setConfirmation({
                                    title: 'Are you sure you want to submit this tour?',
                                    message: 'You will not be able to make changes to this tour later.',
                                    onConfirm: () => {
                                        handleSubmit(e, section);
                                        setConfirmation(null);
                                    }
                                });
                            }}
                            className={`mb-8 ${selectedSection === index ? '' : 'hidden'}`}
                            name={section.title}
                            id={`section-${index}`}>
                            <div className="bg-gray-100 dark:bg-gray-700 shadow-md rounded-lg p-6 mb-4">
                                <h2 className="text-2xl font-semibold mb-4">
                                    {section.title}
                                </h2>

                                {/* Render different content for addon sections */}
                                {section.isAddonSection ? (
                                    <div className="mb-6">
                                        <h3 className="text-xl font-medium mb-4">Additional Services for {section.port}</h3>
                                        <p className="mb-4 text-gray-600 dark:text-gray-300">
                                            Please provide pricing for the following additional services available in {section.port}.
                                        </p>

                                        <div className="overflow-x-auto">
                                            <table className="min-w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600">
                                                <thead>
                                                    <tr className="bg-gray-200 dark:bg-gray-700">
                                                        <th className="py-3 px-4 text-left">Service Name</th>
                                                        <th className="py-3 px-4 text-left">Code</th>
                                                        <th className="py-3 px-4 text-left">Price (VAT Excluded)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {section.addonTours.map((addon, addonIndex) => {
                                                        // Check if this addon has been submitted
                                                        const isSubmitted = submittedAddonPricing[section.port]?.some(
                                                            item => item.tourId === addon.id
                                                        );
                                                        return (
                                                            <tr key={addonIndex} className="border-t border-gray-300 dark:border-gray-600">
                                                                <td className="py-3 px-4">{addon.title}</td>
                                                                <td className="py-3 px-4">{addon.code}</td>
                                                                <td className="py-3 px-4">
                                                                    <input
                                                                        type="number"
                                                                        placeholder="Price"
                                                                        min="0"
                                                                        step="1"
                                                                        className={`w-full p-2 border border-gray-300 dark:border-gray-600 rounded 
                                                                            ${isSubmitted ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-600'} 
                                                                            text-gray-900 dark:text-white`}
                                                                        onChange={(e) => handleAddonPriceChange(section.port, addon.id, e.target.value)}
                                                                        value={addonPricing[section.port]?.[addon.id] || ''}
                                                                        disabled={isSubmitted}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="flex flex-wrap mb-6">
                                            {section.document?.name?.toLowerCase().endsWith('.pdf') ? (
                                                <iframe
                                                    src={`data:application/pdf;base64,${section.document.content}`}
                                                    className="w-full h-96 pr-2"
                                                    title={`${section.title} PDF`}
                                                />
                                            ) : (
                                                <img
                                                    src={section.document ? `data:image/jpeg;base64,${section.document.content}` : section.image}
                                                    alt={`${section.title} PDF`}
                                                    className="w-full pr-2 cursor-pointer"
                                                    onClick={() => handleImageClick(section.document ? `data:image/jpeg;base64,${section.document.content}` : section.image)}
                                                />
                                            )}
                                            {section.supportImage && (
                                                <img
                                                    src={section.supportImage}
                                                    alt={`${section.title} Support Image`}
                                                    className="w-26 pl-2 mt-4 md:mt-0 cursor-pointer"
                                                    onClick={() => handleImageClick(section.supportImage)}
                                                />
                                            )}
                                        </div>
                                        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-5 2xl:grid-cols-7 gap-6">
                                            {section.tiers.map((tier, tierIndex) => {
                                                const isSubmitted = submittedData[section.id];
                                                return (
                                                    <div key={tierIndex} className="space-y-4">
                                                        <h3 className="text-md font-medium">
                                                            {tier.title === 'Other' ? (
                                                                <input
                                                                    type="text"
                                                                    placeholder="Other (please state)"
                                                                    className={`w-full p-2 border border-gray-300 dark:border-gray-600 rounded 
                                                                        ${isSubmitted ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-600'} 
                                                                        text-gray-900 dark:text-white`}
                                                                    onChange={(e) => handleOtherTierChange(section.title, e.target.value)}
                                                                    value={otherTier[section.title] || ''}
                                                                    disabled={isSubmitted}
                                                                />
                                                            ) : (
                                                                <>
                                                                    <p>{tier.title}</p>
                                                                    <p className="text-red-500 text-xs">(VAT Excluded, الاسعار بدون ضريبة القيمة المضافة)</p>
                                                                </>
                                                            )}
                                                        </h3>
                                                        <label>
                                                            ADULT
                                                            <br />
                                                            (12+ years old)
                                                        </label>
                                                        <input
                                                            type="number"
                                                            placeholder="Adult Rate"
                                                            min="0"
                                                            step="1"
                                                            className={`w-full p-2 border border-gray-300 dark:border-gray-600 rounded 
                                                                ${isSubmitted ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-600'} 
                                                                text-gray-900 dark:text-white`}
                                                            onChange={(e) => handlePriceChange(section.title, tier.title === 'Other' ? otherTier[section.title] : tier.id, 'adultRate', e.target.value)}
                                                            value={pricing[section.title]?.[tier.id]?.adultRate || ''}
                                                            required={tier.title !== 'Other'}
                                                            disabled={isSubmitted}
                                                        />
                                                        <label>
                                                            CHILD
                                                            <br />
                                                            (3-11.99 years old)
                                                        </label>
                                                        <input
                                                            type="number"
                                                            placeholder="Child Rate"
                                                            min="0"
                                                            step="1"
                                                            className={`w-full p-2 border border-gray-300 dark:border-gray-600 rounded 
                                                                ${isSubmitted ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-600'} 
                                                                text-gray-900 dark:text-white`}
                                                            onChange={(e) => handlePriceChange(section.title, tier.title === 'Other' ? otherTier[section.title] : tier.id, 'childRate', e.target.value)}
                                                            value={pricing[section.title]?.[tier.id]?.childRate || ''}
                                                            required={tier.title !== 'Other'}
                                                            disabled={isSubmitted}
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>

                                        {/* Add new capacity input fields */}
                                        <div className="mt-8 border-t border-gray-300 dark:border-gray-600 pt-6">
                                            <h3 className="text-xl font-medium mb-4">Tour Capacity Information</h3>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                                <div className="space-y-2">
                                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                        Departures
                                                        <br />
                                                        <span className="text-xs text-gray-500 dark:text-gray-400">(Number of departures per day)</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        placeholder="Enter number of departures"
                                                        min="1"
                                                        step="1"
                                                        className={`w-full p-2 border border-gray-300 dark:border-gray-600 rounded 
                                                            ${submittedData[section.id] ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-600'} 
                                                            text-gray-900 dark:text-white`}
                                                        onChange={(e) => handleCapacityChange(section.id, 'departures', e.target.value)}
                                                        value={tourCapacity[section.id]?.departures || ''}
                                                        required
                                                        disabled={submittedData[section.id]}
                                                    />
                                                </div>
                                                <div className="space-y-2">
                                                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                        Max Capacity
                                                        <br />
                                                        <span className="text-xs text-gray-500 dark:text-gray-400">(Maximum capacity per departure)</span>
                                                    </label>
                                                    <input
                                                        type="number"
                                                        placeholder="Enter maximum capacity"
                                                        min="1"
                                                        step="1"
                                                        className={`w-full p-2 border border-gray-300 dark:border-gray-600 rounded 
                                                            ${submittedData[section.id] ? 'bg-gray-100 dark:bg-gray-700' : 'bg-white dark:bg-gray-600'} 
                                                            text-gray-900 dark:text-white`}
                                                        onChange={(e) => handleCapacityChange(section.id, 'maxCapacity', e.target.value)}
                                                        value={tourCapacity[section.id]?.maxCapacity || ''}
                                                        required
                                                        disabled={submittedData[section.id]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/* Display document information if available - moved here */}
                            {submittedData[section.id]?.document && (
                                <div className="bg-gray-100 dark:bg-gray-700 shadow-md rounded-lg p-6 mb-4">
                                    <h3 className="text-xl font-medium mb-4">Submitted Document</h3>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center">
                                            <svg className="w-6 h-6 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                                            </svg>
                                            <span className="text-gray-700 dark:text-gray-300 font-medium truncate max-w-xs">{submittedData[section.id].document.name}</span>
                                        </div>
                                        <a
                                            href={submittedData[section.id].document.downloadUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                        >
                                            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                                            </svg>
                                            Download File
                                        </a>
                                    </div>
                                </div>
                            )}
                            <div className="flex justify-between">
                                <button
                                    type="submit"
                                    className={`w-1/2 py-3 px-4 rounded-lg transition duration-300
                                        ${!isBiddingOpen || (section.isAddonSection ?
                                            (submittedAddonPricing[section.port]?.length > 0 &&
                                                section.addonTours.every(addon =>
                                                    submittedAddonPricing[section.port]?.some(item => item.tourId === addon.id)
                                                )) :
                                            submittedData[section.id])
                                            ? 'bg-gray-400 cursor-not-allowed text-gray-200'
                                            : 'bg-blue-600 hover:bg-blue-700 text-white'
                                        }`}
                                    disabled={!isBiddingOpen || (section.isAddonSection ?
                                        (submittedAddonPricing[section.port]?.length > 0 &&
                                            section.addonTours.every(addon =>
                                                submittedAddonPricing[section.port]?.some(item => item.tourId === addon.id)
                                            )) :
                                        submittedData[section.id])}
                                >
                                    {section.isAddonSection
                                        ? ((submittedAddonPricing[section.port]?.length > 0 &&
                                            section.addonTours.every(addon =>
                                                submittedAddonPricing[section.port]?.some(item => item.tourId === addon.id)
                                            ))
                                            ? 'All Services Submitted'
                                            : `Submit Additional Services for ${section.port}`)
                                        : submittedData[section.id]
                                            ? 'Already Submitted'
                                            : `Submit Pricing for ${section.title}`}
                                </button>
                                <button
                                    type="button"
                                    onClick={handleSkip}
                                    className={`w-1/2 py-3 px-4 rounded-lg transition duration-300 ml-4
                                        ${section.isAddonSection ?
                                            (submittedAddonPricing[section.port]?.length > 0 &&
                                                section.addonTours.every(addon =>
                                                    submittedAddonPricing[section.port]?.some(item => item.tourId === addon.id)
                                                )) :
                                            submittedData[section.id]
                                                ? 'bg-gray-400 cursor-not-allowed text-gray-200'
                                                : 'bg-gray-600 hover:bg-gray-700 text-white'
                                        }`}
                                    disabled={section.isAddonSection ?
                                        (submittedAddonPricing[section.port]?.length > 0 &&
                                            section.addonTours.every(addon =>
                                                submittedAddonPricing[section.port]?.some(item => item.tourId === addon.id)
                                            )) :
                                        submittedData[section.id]}
                                >
                                    Skip
                                </button>
                            </div>
                            {/* Add the file upload section - only for normal tours, not addon sections */}
                            {!section.isAddonSection && (
                                <div className="mt-6 border-t border-gray-300 dark:border-gray-600 pt-4">
                                    <h3 className="text-xl font-medium mb-2">Menu & Venue Details (Optional)</h3>
                                    <p className="mb-4 text-gray-600 dark:text-gray-300">
                                        Attach an Excel file with menu and venue details for this tour.
                                    </p>

                                    <div className="flex items-center space-x-4">
                                        {!fileAttachments[section.id] ? (
                                            <div className="flex-1">
                                                <label
                                                    htmlFor={`file-upload-${section.id}`}
                                                    className="cursor-pointer bg-white dark:bg-gray-600 rounded-md font-medium text-blue-600 dark:text-blue-300 hover:text-blue-500 dark:hover:text-blue-400 focus-within:outline-none"
                                                >
                                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                            <div className="flex text-sm text-gray-600 dark:text-gray-300">
                                                                <span>Upload Excel file</span>
                                                                <input
                                                                    id={`file-upload-${section.id}`}
                                                                    name="file-upload"
                                                                    type="file"
                                                                    accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                    className="sr-only"
                                                                    onChange={(e) => handleFileChange(section, e)}
                                                                    disabled={submittedData[section.id]}
                                                                />
                                                            </div>
                                                            <p className="text-xs text-gray-500 dark:text-gray-400">
                                                                Excel files only (.xls, .xlsx)
                                                            </p>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        ) : (
                                            <div className="flex-1 bg-white dark:bg-gray-600 rounded-md px-4 py-3">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center">
                                                        <svg className="w-8 h-8 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                        </svg>
                                                        <span className="text-gray-800 dark:text-gray-200 truncate max-w-xs">
                                                            {fileAttachments[section.id].name}
                                                        </span>
                                                    </div>

                                                    {!submittedData[section.id] && (
                                                        <button
                                                            type="button"
                                                            onClick={() => handleRemoveFile(section.id)}
                                                            className="text-red-500 hover:text-red-700"
                                                        >
                                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                                            </svg>
                                                        </button>
                                                    )}
                                                </div>

                                                {fileUploadProgress[section.id] > 0 && fileUploadProgress[section.id] < 100 && (
                                                    <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                                                        <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${fileUploadProgress[section.id]}%` }}></div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </form>
                    ))}
                </div>
                {maximizedImage && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 overflow-auto">
                        <div className="relative max-w-full max-h-full" style={{ marginTop: '101px' }}>
                            <div className="overflow-auto">
                                <img src={maximizedImage} alt="Maximized" className="max-w-full max-h-full" />
                            </div>
                            <button
                                onClick={handleCloseMaximizedImage}
                                className="absolute top-1 right-1 bg-red-500 hover:bg-red-600 text-white font-bold px-6 py-3 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
                                style={{ top: '-50px' }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default ServiceProviderRFP;
