import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";

export default function RoleProtectedRoute({ children, requiredRules = [] }) {
    const { user, loading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading) {
            if (!user) {
                navigate("/signin", { replace: true });
                return;
            }

            // Check if user has the required rules
            const hasRequiredRules = requiredRules.every(rule =>
                user.rules && user.rules.includes(rule)
            );

            if (!hasRequiredRules) {
                // Redirect to a forbidden page or home
                navigate("/", { replace: true });
            }
        }
    }, [navigate, user, loading, requiredRules]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 dark:border-blue-300"></div>
            </div>
        );
    }

    // Only render children if user has required rules
    return user && requiredRules.every(rule => user.rules?.includes(rule)) ? children : null;
} 