import { useState, useEffect, useMemo, useRef } from 'react';
import { Tab } from '@headlessui/react';
// import { PencilIcon, TrashIcon, ChevronUpIcon, ChevronDownIcon, ArrowUpIcon, ArrowDownIcon, CheckIcon, DocumentIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
// import Modal from '../components/Modal';
// import Tour from '../components/Tour';
// import { useNavigate } from 'react-router-dom';
import axios from "axios";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export function ShoreExcursionAdmin() {
    const [vendors, setVendors] = useState([]);
    const [tours, setTours] = useState([]);
    const [submissions, setSubmissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [selectedTours, setSelectedTours] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [expandedAssignments, setExpandedAssignments] = useState(new Set());
    const [expandedPorts, setExpandedPorts] = useState(new Set());
    const [vendorsLoading, setVendorsLoading] = useState(false);
    const [toursLoading, setToursLoading] = useState(false);
    const [submissionsLoading, setSubmissionsLoading] = useState(false);
    const [assignmentsLoading, setAssignmentsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [assignedVendorsLoading, setAssignedVendorsLoading] = useState(false);
    const [biddingStatus, setBiddingStatus] = useState('Closed');
    const [biddingStatusLoading, setBiddingStatusLoading] = useState(false);
    const [biddingActionLoading, setBiddingActionLoading] = useState(false);
    const [submittedBiddings, setSubmittedBiddings] = useState([]);
    const [submittedBiddingsLoading, setSubmittedBiddingsLoading] = useState(false);
    const [deletingVendors, setDeletingVendors] = useState(new Set());
    const [bulkDeletingVendor, setBulkDeletingVendor] = useState(null);
    const [removeAllConfirmation, setRemoveAllConfirmation] = useState({ show: false, vendor: null });
    const [modalRemoveLoading, setModalRemoveLoading] = useState(false);
    const [newTour, setNewTour] = useState({ title: '', port: 'Jeddah', description: '', type: 'Normal' });
    const [selectedFile, setSelectedFile] = useState(null);
    const [isAddingTour, setIsAddingTour] = useState(false);
    const [editingTour, setEditingTour] = useState(null);
    const [isEditingTour, setIsEditingTour] = useState(false);

    // Tours table pagination and filtering states
    const [currentPage, setCurrentPage] = useState(1);
    const [toursPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterPort, setFilterPort] = useState('All');
    const [sortConfig, setSortConfig] = useState({ key: 'code', direction: 'ascending' });

    // Tours selection states
    const [tourSearchTerm, setTourSearchTerm] = useState('');
    const [tourFilterPort, setTourFilterPort] = useState('All');
    const [tourFilterType, setTourFilterType] = useState('All');
    const [tourGroupByPort, setTourGroupByPort] = useState(true);

    // Add new state variables for vendor filtering and grouping
    const [vendorSearchTerm, setVendorSearchTerm] = useState('');
    const [vendorFilterType, setVendorFilterType] = useState('All');
    const [vendorGroupByType, setVendorGroupByType] = useState(true);

    // Add new state variables for cycle management
    const [cycleInfo, setCycleInfo] = useState(null);
    const [currentRound, setCurrentRound] = useState(null);
    const [startingNewRound, setStartingNewRound] = useState(false);

    useEffect(() => {
        const fetchInitialData = async () => {
            setLoading(true);
            try {
                // Get token from localStorage
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                // Fetch vendors with authorization header
                setVendorsLoading(true);
                const vendorsResponse = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/tour-providers`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!vendorsResponse.ok) {
                    throw new Error('Failed to fetch vendors');
                }

                const vendorsData = await vendorsResponse.json();
                setVendors(vendorsData);
                setVendorsLoading(false);

                // Fetch tours
                setToursLoading(true);
                const toursResponse = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRFPTours`, {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                });

                if (!toursResponse.ok) {
                    throw new Error('Failed to fetch tours');
                }

                const toursData = await toursResponse.json();
                setTours(toursData);
                setToursLoading(false);

                // Fetch submissions
                // const submissionsResponse = await fetch('/api/submissions');
                // const submissionsData = await submissionsResponse.json();
                setSubmissions([
                    {
                        id: 1,
                        vendorName: 'Vendor A',
                        tourName: 'Cultural Jeddah',
                        submittedAt: '2024-03-20',
                        status: 'completed',
                        pricing: {
                            adult: 100,
                            child: 50,
                            infant: 0
                        }
                    },
                ]);

                // Fetch assigned vendors
                setAssignedVendorsLoading(true);
                const assignedResponse = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getAssignedVendors`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!assignedResponse.ok) {
                    throw new Error('Failed to fetch assigned vendors');
                }

                const assignedData = await assignedResponse.json();

                const groupedAssignments = assignedData.reduce((acc, item) => {
                    const existingTour = acc.find(t => t.tourId === item.tourId);
                    if (existingTour) {
                        existingTour.assignedVendors.push({
                            id: item.id,
                            email: item.email,
                            submitted: item.submitted
                        });
                    } else {
                        acc.push({
                            id: item.tourId,
                            tourName: item.tourName,
                            tourId: item.tourId,
                            assignedVendors: [{
                                id: item.id,
                                email: item.email,
                                submitted: item.submitted
                            }]
                        });
                    }
                    return acc;
                }, []);

                // Then, add ports after tours are loaded
                const assignmentsWithPorts = groupedAssignments.map(assignment => ({
                    ...assignment,
                    port: toursData.find(t => t.id === assignment.tourId)?.port || 'Unknown',
                    code: toursData.find(t => t.id === assignment.tourId)?.code || 'Unknown'
                }));

                setAssignments(assignmentsWithPorts);
                setAssignedVendorsLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        const init = async () => {
            await fetchInitialData();
        };
        init();
    }, []);

    useEffect(() => {
        if (assignments.length > 0 && tours.length > 0) {
            const updatedAssignments = assignments.map(assignment => ({
                ...assignment,
                port: tours.find(t => t.id === assignment.tourId)?.port || 'Unknown',
                code: tours.find(t => t.id === assignment.tourId)?.code || 'Unknown'
            }));
            setAssignments(updatedAssignments);
        }
    }, [tours]);

    // Move fetchBiddingStatus outside useEffect so it can be called from other functions
    const fetchBiddingStatus = async () => {
        setBiddingStatusLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/biddingStatus`,
                {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to fetch bidding status');
            }

            const data = await response.json();
            setBiddingStatus(data.status);

            // Update cycle and round information
            if (data.cycleActive) {
                setCycleInfo(data.cycleInfo);
                setCurrentRound(data.round);
            } else {
                setCycleInfo(null);
                setCurrentRound(null);
            }
        } catch (error) {
            console.error('Error fetching bidding status:', error);
        } finally {
            setBiddingStatusLoading(false);
        }
    };

    // Update the useEffect to call fetchBiddingStatus
    useEffect(() => {
        fetchBiddingStatus();
    }, []);

    useEffect(() => {
        const fetchSubmittedBiddings = async () => {
            setSubmittedBiddingsLoading(true);
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No authentication token found');
                }

                const response = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getSubmittedBiddings`,
                    {
                        headers: {
                            'Authorization': token,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch submitted biddings');
                }

                const data = await response.json();
                console.log(data);
                setSubmittedBiddings(data);
            } catch (error) {
                console.error('Error fetching submitted biddings:', error);
            } finally {
                setSubmittedBiddingsLoading(false);
            }
        };

        fetchSubmittedBiddings();
    }, []);

    const handleVendorSelection = (vendor) => {
        setSelectedVendors(prev =>
            prev.includes(vendor.email)
                ? prev.filter(email => email !== vendor.email)
                : [...prev, vendor.email]
        );
    };

    const handleTourSelection = (tourId) => {
        setSelectedTours(prev =>
            prev.includes(tourId)
                ? prev.filter(id => id !== tourId)
                : [...prev, tourId]
        );
    };

    const handleAssignVendors = async () => {
        try {
            setAssignmentsLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const assignments = selectedVendors.flatMap(email =>
                selectedTours.map(tourId => ({ email, tourId }))
            );

            await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/assignRFPTours`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ assignments }),
            });

            setShowConfirmation(true);
            setSelectedVendors([]);
            setSelectedTours([]);
        } catch (error) {
            console.error('Error assigning vendors:', error);
        } finally {
            setAssignmentsLoading(false);
        }
    };

    // Replace startNewBiddingRound with these two functions
    const startBidding = async () => {
        setBiddingActionLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/startBidding`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to start bidding');
            }

            const data = await response.json();
            setBiddingStatus('Started');

            // Update cycle information
            if (data.data && data.data.cycle) {
                setCycleInfo({
                    title: data.data.cycle.fields.Title,
                    startDate: data.data.cycle.fields.StartDate
                });
            }

            // Update round information
            if (data.data && data.data.round) {
                setCurrentRound({
                    number: data.data.round.fields.Round,
                    startDate: data.data.round.fields.StartDate,
                    endDate: null
                });
            }
        } catch (error) {
            console.error('Error starting bidding:', error);
            alert(error.message);
        } finally {
            setBiddingActionLoading(false);
        }
    };

    const closeBidding = async () => {
        setBiddingActionLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/closeBidding`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to close bidding');
            }

            setBiddingStatus('Closed');

            // Reset cycle and round info
            setCycleInfo(null);
            setCurrentRound(null);
        } catch (error) {
            console.error('Error closing bidding:', error);
            alert(error.message);
        } finally {
            setBiddingActionLoading(false);
        }
    };

    // Add clear handlers
    const clearVendors = () => {
        setSelectedVendors([]);
    };

    const clearTours = () => {
        setSelectedTours([]);
    };

    // Add select all handlers
    const handleSelectAllVendors = () => {
        if (selectedVendors.length === vendors.length) {
            setSelectedVendors([]);
        } else {
            setSelectedVendors(vendors.map(v => v.email));
        }
    };

    const handleSelectAllTours = () => {
        if (selectedTours.length === tours.length) {
            setSelectedTours([]);
        } else {
            setSelectedTours(tours.map(t => t.id));
        }
    };

    // Add handler for toggling expansion
    const toggleExpanded = (assignmentId) => {
        setExpandedAssignments(prev => {
            const newSet = new Set(prev);
            if (newSet.has(assignmentId)) {
                newSet.delete(assignmentId);
            } else {
                newSet.add(assignmentId);
            }
            return newSet;
        });
    };

    // Update the handleRemoveVendor function
    const handleRemoveVendor = async (tourId, vendor) => {
        try {
            setDeletingVendors(prev => new Set([...prev, vendor.email]));
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/removeAssignedVendor`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: vendor.email,
                        tourId: tourId
                    }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to remove vendor');
            }

            // Update assignments locally after successful removal
            setAssignments(prev => prev.map(assignment => {
                if (assignment.id === tourId) {
                    return {
                        ...assignment,
                        assignedVendors: assignment.assignedVendors.filter(v => v.email !== vendor.email)
                    };
                }
                return assignment;
            }));
        } catch (error) {
            console.error('Error removing vendor:', error);
            alert(error.message);
        } finally {
            setDeletingVendors(prev => {
                const newSet = new Set(prev);
                newSet.delete(vendor.email);
                return newSet;
            });
        }
    };

    // Add handler for toggling port expansion
    const togglePortExpanded = (port) => {
        setExpandedPorts(prev => {
            const newSet = new Set(prev);
            if (newSet.has(port)) {
                newSet.delete(port);
            } else {
                newSet.add(port);
            }
            return newSet;
        });
    };

    // Update the handleRemoveVendorFromAll function to show confirmation first
    const handleRemoveVendorFromAll = async (vendor) => {
        setRemoveAllConfirmation({ show: true, vendor });
    };

    // Add new function to handle the actual removal after confirmation
    const confirmRemoveVendorFromAll = async () => {
        const vendor = removeAllConfirmation.vendor;
        setModalRemoveLoading(true);
        try {
            setBulkDeletingVendor(vendor.email);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/removeVendorFromAllTours`,
                {
                    method: 'DELETE',
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: vendor.email
                    }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to remove vendor from all tours');
            }

            // Update assignments locally after successful removal
            setAssignments(prev => prev.map(assignment => ({
                ...assignment,
                assignedVendors: assignment.assignedVendors.filter(v => v.email !== vendor.email)
            })));

        } catch (error) {
            console.error('Error removing vendor from all tours:', error);
            alert(error.message);
        } finally {
            setBulkDeletingVendor(null);
            setModalRemoveLoading(false);
            setRemoveAllConfirmation({ show: false, vendor: null });
            window.location.reload();
        }
    };

    // Add Confirmation Modal Component
    const ConfirmationModal = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                <div className="mt-3 text-center">
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <svg
                            className="h-6 w-6 text-green-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    </div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">
                        Assignment Successful!
                    </h3>
                    <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                            The vendors have been successfully assigned to the selected tours.
                        </p>
                    </div>
                    <div className="items-center px-4 py-3">
                        <button
                            onClick={() => window.location.reload()}
                            className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300"
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    // Add Loading Spinner Component
    const LoadingSpinner = () => (
        <div className="flex justify-center items-center">
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-500"></div>
        </div>
    );

    // Add new RemoveAllConfirmationModal component
    const RemoveAllConfirmationModal = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                <div className="mt-3 text-center">
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                        <svg
                            className="h-6 w-6 text-red-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                        </svg>
                    </div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-5">
                        Remove Vendor from All Tours
                    </h3>
                    <div className="mt-2 px-7 py-3">
                        <p className="text-sm text-gray-500 dark:text-gray-400">
                            Are you sure you want to remove {removeAllConfirmation.vendor?.name || removeAllConfirmation.vendor?.email} from all tours? This action cannot be undone.
                        </p>
                    </div>
                    <div className="flex justify-center space-x-4 px-4 py-3">
                        <button
                            onClick={() => setRemoveAllConfirmation({ show: false, vendor: null })}
                            disabled={modalRemoveLoading}
                            className="px-4 py-2 bg-gray-300 text-gray-700 text-base font-medium rounded-md shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 disabled:opacity-50"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={confirmRemoveVendorFromAll}
                            disabled={modalRemoveLoading}
                            className="px-4 py-2 bg-red-600 text-white text-base font-medium rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 disabled:opacity-50 min-w-[100px]"
                        >
                            {modalRemoveLoading ? (
                                <div className="flex items-center justify-center space-x-2">
                                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    <span>Removing...</span>
                                </div>
                            ) : (
                                'Remove'
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleAddTour = async (e) => {
        e.preventDefault();
        setIsAddingTour(true);

        try {
            const formData = new FormData();
            formData.append('title', newTour.title);
            formData.append('port', newTour.port);
            formData.append('code', newTour.code);
            formData.append('type', newTour.type);
            if (selectedFile && newTour.type === 'Normal') {
                formData.append('document', selectedFile);
            }

            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/createTour`, {
                method: 'POST',
                headers: {
                    'Authorization': token,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to create tour');
            }

            // Reset form and refresh tours
            setNewTour({ title: '', port: '', code: '', type: 'Normal' });
            setSelectedFile(null);
            // Refresh tours list
            await fetchTours();
        } catch (error) {
            console.error('Error adding tour:', error);
            alert('Failed to add tour');
        } finally {
            setIsAddingTour(false);
        }
    };

    const handleEditTour = (tour) => {
        setEditingTour(tour);
    };

    const handleDeleteTour = async (tourId) => {
        if (!window.confirm('Are you sure you want to delete this tour?')) {
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/tours/${tourId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete tour');
            }

            // Refresh tours list
            await fetchTours();
        } catch (error) {
            console.error('Error deleting tour:', error);
            alert('Failed to delete tour');
        }
    };

    // Sort function for tours table
    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Filter tours by search term and port
    const filteredTours = tours.filter((tour) => {
        const matchesSearch =
            tour.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tour.code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tour.port?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (tour.type && tour.type.toLowerCase().includes(searchTerm.toLowerCase()));

        const matchesPort = filterPort === 'All' || tour.port === filterPort;

        return matchesSearch && matchesPort;
    });

    // Sort tours based on sort configuration
    const sortedTours = useMemo(() => {
        const sortableTours = [...filteredTours];
        if (sortConfig.key) {
            sortableTours.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableTours;
    }, [filteredTours, sortConfig]);

    // Pagination logic
    const indexOfLastTour = currentPage * toursPerPage;
    const indexOfFirstTour = indexOfLastTour - toursPerPage;
    const currentTours = sortedTours.slice(indexOfFirstTour, indexOfLastTour);
    const totalPages = Math.ceil(sortedTours.length / toursPerPage);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Get unique ports for filter tabs
    const uniquePorts = useMemo(() => {
        const ports = ['All', ...new Set(tours.map(tour => tour.port))];
        return ports.filter(Boolean); // Remove any null/undefined values
    }, [tours]);

    // Get unique tour types for filter tabs
    const uniqueTourTypes = useMemo(() => {
        const types = ['All', ...new Set(tours.map(tour => tour.type || 'Normal'))];
        return types.filter(Boolean); // Remove any null/undefined values
    }, [tours]);

    // Filter tours for selection
    const filteredSelectionTours = useMemo(() => {
        return tours.filter(tour => {
            const matchesSearch =
                !tourSearchTerm ||
                tour.name?.toLowerCase().includes(tourSearchTerm.toLowerCase()) ||
                tour.code?.toLowerCase().includes(tourSearchTerm.toLowerCase());

            const matchesPort = tourFilterPort === 'All' || tour.port === tourFilterPort;
            const matchesType = tourFilterType === 'All' || (tour.type || 'Normal') === tourFilterType;

            return matchesSearch && matchesPort && matchesType;
        });
    }, [tours, tourSearchTerm, tourFilterPort, tourFilterType]);

    // Group tours by port for better organization
    const toursGroupedByPort = useMemo(() => {
        const groupedTours = {};

        filteredSelectionTours.forEach(tour => {
            const port = tour.port || 'Unknown';
            if (!groupedTours[port]) {
                groupedTours[port] = [];
            }
            groupedTours[port].push(tour);
        });

        // Sort ports alphabetically
        return Object.entries(groupedTours)
            .sort(([portA], [portB]) => portA.localeCompare(portB))
            .map(([port, tours]) => ({
                port,
                tours: tours.sort((a, b) => a.name.localeCompare(b.name))
            }));
    }, [filteredSelectionTours]);

    // Add fetchTours function
    const fetchTours = async () => {
        setToursLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_CSH_BASE_URL}/api/v1/getRFPTours`, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch tours');
            }

            const data = await response.json();
            setTours(data);
        } catch (error) {
            console.error('Error fetching tours:', error);
        } finally {
            setToursLoading(false);
        }
    };

    // Add this new EditTourModal component near other modal components
    const EditTourModal = () => {
        const [editForm, setEditForm] = useState({
            title: editingTour?.name || '',
            port: editingTour?.port || '',
            code: editingTour?.code || ''
        });
        const [editFile, setEditFile] = useState(null);

        useEffect(() => {
            if (editingTour) {
                setEditForm({
                    title: editingTour.name,
                    port: editingTour.port,
                    code: editingTour.code
                });
            }
        }, [editingTour]);

        const handleEditSubmit = async (e) => {
            e.preventDefault();
            setIsEditingTour(true);

            try {
                const token = localStorage.getItem('token');
                const formData = new FormData();
                formData.append('title', editForm.title);
                formData.append('port', editForm.port);
                formData.append('code', editForm.code);
                if (editFile) {
                    formData.append('document', editFile);
                }

                const response = await fetch(
                    `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/updateTour/${editingTour.id}`,
                    {
                        method: 'PUT',
                        headers: {
                            'Authorization': token,
                        },
                        body: formData,
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to update tour');
                }

                // Refresh tours list
                await fetchTours();
                setEditingTour(null);
            } catch (error) {
                console.error('Error updating tour:', error);
                alert('Failed to update tour');
            } finally {
                setIsEditingTour(false);
            }
        };

        return (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
                <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white dark:bg-gray-800">
                    <div className="mt-3">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                            Edit Tour
                        </h3>
                        <form onSubmit={handleEditSubmit} className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Tour Name
                                </label>
                                <input
                                    type="text"
                                    value={editForm.title}
                                    onChange={(e) => setEditForm({ ...editForm, title: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Port
                                </label>
                                <select
                                    value={editForm.port}
                                    onChange={(e) => setEditForm({ ...editForm, port: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                    required
                                >
                                    <option value="Jeddah">Jeddah</option>
                                    <option value="Dammam">Dammam</option>
                                    <option value="Yanbu">Yanbu</option>
                                    <option value="Alwajh">Alwajh</option>
                                </select>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Tour Code
                                </label>
                                <input
                                    type="text"
                                    value={editForm.code}
                                    onChange={(e) => setEditForm({ ...editForm, code: e.target.value })}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                    required
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                    Update Document
                                </label>
                                <input
                                    type="file"
                                    onChange={(e) => setEditFile(e.target.files[0])}
                                    className="mt-1 block w-full text-sm text-gray-500 dark:text-gray-300
                                        file:mr-4 file:py-2 file:px-4
                                        file:rounded-md file:border-0
                                        file:text-sm file:font-semibold
                                        file:bg-blue-50 file:text-blue-700
                                        hover:file:bg-blue-100
                                        dark:file:bg-gray-600 dark:file:text-gray-200"
                                />
                            </div>
                            <div className="flex justify-end space-x-3">
                                <button
                                    type="button"
                                    onClick={() => setEditingTour(null)}
                                    className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    disabled={isEditingTour}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
                                >
                                    {isEditingTour ? (
                                        <div className="flex items-center space-x-2">
                                            <LoadingSpinner />
                                            <span>Updating...</span>
                                        </div>
                                    ) : (
                                        'Update Tour'
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    };

    // Add this helper function at the top level of the component
    const groupBiddingsByTour = (biddings) => {
        return biddings.reduce((acc, bidding) => {
            // Updated key to include cycleId
            const key = `${bidding.tourId}_${bidding.code}`;
            if (!acc[key]) {
                acc[key] = {
                    tourId: bidding.tourId,
                    code: bidding.code,
                    port: bidding.port,
                    vendors: {}
                };
            }

            // Create a unique vendor key that includes the cycle
            const vendorKey = bidding.cycleId
                ? `${bidding.vendor}_cycle_${bidding.cycleId}`
                : bidding.vendor;

            if (!acc[key].vendors[vendorKey]) {
                acc[key].vendors[vendorKey] = [];
            }

            acc[key].vendors[vendorKey].push(bidding);
            return acc;
        }, {});
    };

    // Table sort header component
    const SortableHeader = ({ label, sortKey }) => {
        const isActive = sortConfig.key === sortKey;
        return (
            <th
                scope="col"
                onClick={() => requestSort(sortKey)}
                className="px-4 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600"
            >
                <div className="flex items-center space-x-1">
                    <span>{label}</span>
                    {isActive && (
                        <span>
                            {sortConfig.direction === 'ascending' ? '↑' : '↓'}
                        </span>
                    )}
                </div>
            </th>
        );
    };

    // Pagination component
    const Pagination = () => {
        const pageNumbers = [];

        // Calculate visible page numbers with ellipsis
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, currentPage + 2);

        // Ensure we always show 5 pages if possible
        if (endPage - startPage < 4) {
            if (startPage === 1) {
                endPage = Math.min(5, totalPages);
            } else if (endPage === totalPages) {
                startPage = Math.max(1, totalPages - 4);
            }
        }

        // Add page numbers
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return (
            <div className="mt-4 flex justify-between items-center">
                <div className="text-sm text-gray-500 dark:text-gray-400">
                    Showing {indexOfFirstTour + 1} to {Math.min(indexOfLastTour, sortedTours.length)} of {sortedTours.length} tours
                </div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                    <button
                        onClick={() => paginate(Math.max(1, currentPage - 1))}
                        disabled={currentPage === 1}
                        className={`relative inline-flex items-center px-2 py-2 rounded-l-md border ${currentPage === 1
                            ? 'border-gray-300 bg-gray-100 text-gray-400 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-500'
                            : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700'
                            }`}
                    >
                        <span className="sr-only">Previous</span>
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                    </button>

                    {startPage > 1 && (
                        <>
                            <button
                                onClick={() => paginate(1)}
                                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === 1
                                    ? 'bg-blue-50 text-blue-600 dark:bg-blue-900 dark:text-blue-200'
                                    : 'text-gray-700 hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300'
                                    }`}
                            >
                                1
                            </button>
                            {startPage > 2 && (
                                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300">
                                    ...
                                </span>
                            )}
                        </>
                    )}

                    {pageNumbers.map(number => (
                        <button
                            key={number}
                            onClick={() => paginate(number)}
                            className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${currentPage === number
                                ? 'z-10 bg-blue-50 border-blue-500 text-blue-600 dark:bg-blue-900 dark:border-blue-500 dark:text-blue-200'
                                : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700'
                                }`}
                        >
                            {number}
                        </button>
                    ))}

                    {endPage < totalPages && (
                        <>
                            {endPage < totalPages - 1 && (
                                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300">
                                    ...
                                </span>
                            )}
                            <button
                                onClick={() => paginate(totalPages)}
                                className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === totalPages
                                    ? 'bg-blue-50 text-blue-600 dark:bg-blue-900 dark:text-blue-200'
                                    : 'text-gray-700 hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700'
                                    }`}
                            >
                                {totalPages}
                            </button>
                        </>
                    )}

                    <button
                        onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
                        disabled={currentPage === totalPages}
                        className={`relative inline-flex items-center px-2 py-2 rounded-r-md border ${currentPage === totalPages
                            ? 'border-gray-300 bg-gray-100 text-gray-400 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-500'
                            : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700'
                            }`}
                    >
                        <span className="sr-only">Next</span>
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                    </button>
                </nav>
            </div>
        );
    };

    // Reset pagination when tours change
    useEffect(() => {
        if (tours.length > 0) {
            setCurrentPage(1);
        }
    }, [tours.length]);

    // Add new function to start a new bidding round
    const startNewBiddingRound = async () => {
        setStartingNewRound(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(
                `${process.env.REACT_APP_CSH_BASE_URL}/api/v1/startNewBiddingRound`,
                {
                    method: 'POST',
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to start new bidding round');
            }

            const data = await response.json();
            setBiddingStatus('Started');

            // Update round information
            if (data.data) {
                setCurrentRound({
                    number: data.data.fields.Round,
                    startDate: data.data.fields.StartDate,
                    endDate: null
                });
            }

            // Fetch updated bidding status to get the latest info
            await fetchBiddingStatus();
        } catch (error) {
            console.error('Error starting new bidding round:', error);
            alert(error.message);
        } finally {
            setStartingNewRound(false);
        }
    };

    // Update the tab panel for bidding management
    // Replace the existing bidding tab panel with this updated version
    const BiddingManagementPanel = () => (
        <Tab.Panel className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
            <div className="space-y-6">
                <div className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg">
                    <h3 className="text-lg font-semibold text-blue-900 dark:text-blue-100 mb-2">
                        Current Bidding Status
                    </h3>
                    {biddingStatusLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="space-y-2">
                            <p className="text-blue-800 dark:text-blue-200">
                                Bidding is currently {biddingStatus}
                            </p>
                            {cycleInfo && (
                                <div className="mt-2 p-3 bg-white dark:bg-gray-700 rounded-lg">
                                    <h4 className="font-medium text-gray-900 dark:text-gray-100">
                                        Active Cycle: {cycleInfo.title}
                                    </h4>
                                    <p className="text-sm text-gray-600 dark:text-gray-300">
                                        Started: {new Date(cycleInfo.startDate).toLocaleString()}
                                    </p>
                                    {currentRound && (
                                        <div className="mt-2 p-2 bg-blue-50 dark:bg-blue-800 rounded">
                                            <h5 className="font-medium text-blue-900 dark:text-blue-100">
                                                Current Round: {currentRound.number}
                                            </h5>
                                            <p className="text-sm text-blue-700 dark:text-blue-200">
                                                Started: {new Date(currentRound.startDate).toLocaleString()}
                                            </p>
                                            {currentRound.endDate && (
                                                <p className="text-sm text-blue-700 dark:text-blue-200">
                                                    Ended: {new Date(currentRound.endDate).toLocaleString()}
                                                </p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>

                <div className="flex flex-col space-y-4">
                    {biddingStatus === 'Started' && (
                        <div className="flex space-x-4">
                            <button
                                onClick={startNewBiddingRound}
                                disabled={startingNewRound}
                                className="flex-1 py-2 px-4 rounded-lg text-white bg-green-600 hover:bg-green-700 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
                            >
                                {startingNewRound ? <LoadingSpinner /> : 'Start New Round'}
                            </button>
                            <button
                                onClick={closeBidding}
                                disabled={biddingActionLoading}
                                className="flex-1 py-2 px-4 rounded-lg text-white bg-red-600 hover:bg-red-700 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
                            >
                                {biddingActionLoading ? <LoadingSpinner /> : 'Close Bidding Cycle'}
                            </button>
                        </div>
                    )}

                    {biddingStatus !== 'Started' && (
                        <button
                            onClick={startBidding}
                            disabled={biddingActionLoading}
                            className="w-full py-2 px-4 rounded-lg text-white bg-blue-600 hover:bg-blue-700 transition duration-300 disabled:bg-gray-400 disabled:cursor-not-allowed"
                        >
                            {biddingActionLoading ? <LoadingSpinner /> : 'Start New Bidding Cycle'}
                        </button>
                    )}
                </div>

                <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
                        About Bidding Cycles
                    </h3>
                    <ul className="list-disc pl-5 space-y-1 text-gray-600 dark:text-gray-300">
                        <li>A bidding cycle contains multiple bidding rounds</li>
                        <li>Only one cycle can be active at a time</li>
                        <li>Start a new round to allow vendors to update their bids</li>
                        <li>Close the cycle when the entire bidding process is complete</li>
                    </ul>
                </div>
            </div>
        </Tab.Panel>
    );

    const BiddingHierarchyView = ({ biddings }) => {
        const [expandedNodes, setExpandedNodes] = useState(new Set());

        const toggleNode = (nodeId) => {
            setExpandedNodes(prev => {
                const newSet = new Set(prev);
                if (newSet.has(nodeId)) {
                    newSet.delete(nodeId);
                } else {
                    newSet.add(nodeId);
                }
                return newSet;
            });
        };

        // Group biddings by port -> tour -> cycle -> round -> vendor
        const hierarchicalData = biddings.reduce((acc, bid) => {
            if (!acc[bid.port]) {
                acc[bid.port] = {};
            }
            if (!acc[bid.port][bid.code]) {
                acc[bid.port][bid.code] = {
                    tourId: bid.tourId,
                    cycles: {},
                    // Store the tour type to identify addons
                    isAddon: bid.type === 'Addon'
                };
            }
            const cycleKey = bid.cycleId || 'default';
            if (!acc[bid.port][bid.code].cycles[cycleKey]) {
                acc[bid.port][bid.code].cycles[cycleKey] = {};
            }
            if (!acc[bid.port][bid.code].cycles[cycleKey][bid.roundNumber]) {
                acc[bid.port][bid.code].cycles[cycleKey][bid.roundNumber] = {};
            }
            if (!acc[bid.port][bid.code].cycles[cycleKey][bid.roundNumber][bid.vendor]) {
                acc[bid.port][bid.code].cycles[cycleKey][bid.roundNumber][bid.vendor] = bid;
            }
            return acc;
        }, {});

        const sortTiers = (tiers) => {
            return [...tiers].sort((a, b) => {
                const getNumber = (tier) => {
                    const parts = tier.tier ? tier.tier.split('-') : [];
                    if (parts.length > 0) {
                        const match = parts[0].match(/\d+/);
                        return match ? parseInt(match[0], 10) : 0;
                    }
                    return 0;
                };
                return getNumber(a) - getNumber(b);
            });
        };

        // Group tours by type (normal and addon) for each port
        const groupToursByType = (port, tours) => {
            const normalTours = {};
            const addonTours = {};

            Object.entries(tours).forEach(([tourCode, tourData]) => {
                if (tourData.isAddon) {
                    addonTours[tourCode] = tourData;
                } else {
                    normalTours[tourCode] = tourData;
                }
            });

            return { normalTours, addonTours };
        };

        const NodeHeader = ({ id, title, count, type, isAddon, onClick }) => (
            <div
                onClick={onClick}
                className="flex items-center justify-between p-3 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
            >
                <div className="flex items-center space-x-3">
                    <span className={`transform transition-transform ${expandedNodes.has(id) ? 'rotate-90' : ''}`}>
                        ▶
                    </span>
                    <span className="font-medium">
                        {title}
                        {isAddon && (
                            <span className="ml-2 px-2 py-0.5 bg-orange-100 text-orange-800 dark:bg-orange-900 dark:text-orange-200 rounded-full text-xs">
                                Addon
                            </span>
                        )}
                    </span>
                    {count && (
                        <span className={`
                            px-2 py-0.5 rounded-full text-xs
                            ${type === 'port' ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200' :
                                type === 'tour' ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200' :
                                    type === 'cycle' ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' :
                                        type === 'round' ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200' :
                                            type === 'vendor' ? 'bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200' :
                                                'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200'}
                        `}>
                            {count}
                        </span>
                    )}
                </div>
            </div>
        );

        const PricingTable = ({ tiers, isAddon, bidding }) => (
            <div className="overflow-x-auto">
                {/* Display capacity information if available */}
                {!isAddon && bidding && (bidding.departures || bidding.maxCapacity) && (
                    <div className="mb-4 flex flex-wrap items-center">
                        {bidding.departures && (
                            <div className="mr-6 flex items-center">
                                <span className="bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200 px-2 py-1 rounded-md text-xs font-medium mr-2">
                                    Departures
                                </span>
                                <span className="text-gray-900 dark:text-gray-100 font-medium">
                                    {bidding.departures} per day
                                </span>
                            </div>
                        )}
                        {bidding.maxCapacity && (
                            <div className="flex items-center">
                                <span className="bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200 px-2 py-1 rounded-md text-xs font-medium mr-2">
                                    Max Capacity
                                </span>
                                <span className="text-gray-900 dark:text-gray-100 font-medium">
                                    {bidding.maxCapacity} per departure
                                </span>
                            </div>
                        )}
                    </div>
                )}
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Tier</th>
                            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">
                                {isAddon ? 'Price' : 'Adult'}
                            </th>
                            {!isAddon && (
                                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase">Child</th>
                            )}
                        </tr>
                    </thead>
                    <tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
                        {sortTiers(tiers).map((tier, idx) => (
                            <tr key={idx} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                <td className="px-4 py-2 text-sm text-gray-900 dark:text-gray-100">{tier.tier || `Tier ${tier.tierId}`}</td>
                                <td className="px-4 py-2 text-sm text-gray-900 dark:text-gray-100">{tier.pricing.adultRate}</td>
                                {!isAddon && (
                                    <td className="px-4 py-2 text-sm text-gray-900 dark:text-gray-100">{tier.pricing.childRate}</td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );

        return (
            <div className="space-y-2">
                {Object.entries(hierarchicalData).map(([port, tours]) => {
                    // Group tours by type for this port
                    const { normalTours, addonTours } = groupToursByType(port, tours);
                    const hasAddons = Object.keys(addonTours).length > 0;

                    return (
                        <div key={port} className="border rounded-lg overflow-hidden dark:border-gray-700">
                            <NodeHeader
                                id={port}
                                title={port}
                                count={`${Object.keys(tours).length} Tours${hasAddons ? ` (${Object.keys(addonTours).length} Addons)` : ''}`}
                                type="port"
                                onClick={() => toggleNode(port)}
                            />

                            {expandedNodes.has(port) && (
                                <div className="ml-4 border-l-2 border-gray-200 dark:border-gray-700">
                                    {/* Regular Tours Section */}
                                    {Object.keys(normalTours).length > 0 && (
                                        <>
                                            {Object.entries(normalTours).map(([tourCode, tourData]) => (
                                                <div key={tourCode} className="border-b last:border-b-0 dark:border-gray-700">
                                                    <NodeHeader
                                                        id={`${port}_${tourCode}`}
                                                        title={tourCode}
                                                        count={`${Object.keys(tourData.cycles).length} Cycles`}
                                                        type="tour"
                                                        isAddon={false}
                                                        onClick={() => toggleNode(`${port}_${tourCode}`)}
                                                    />

                                                    {expandedNodes.has(`${port}_${tourCode}`) && (
                                                        <div className="ml-4 border-l-2 border-gray-200 dark:border-gray-700">
                                                            {Object.entries(tourData.cycles).map(([cycleId, rounds]) => (
                                                                <div key={cycleId} className="border-b last:border-b-0 dark:border-gray-700">
                                                                    <NodeHeader
                                                                        id={`${port}_${tourCode}_${cycleId}`}
                                                                        title={cycleId === 'default' ? 'Default Cycle' : `Cycle ${cycleId}`}
                                                                        count={`${Object.keys(rounds).length} Rounds`}
                                                                        type="cycle"
                                                                        onClick={() => toggleNode(`${port}_${tourCode}_${cycleId}`)}
                                                                    />

                                                                    {expandedNodes.has(`${port}_${tourCode}_${cycleId}`) && (
                                                                        <div className="ml-4 border-l-2 border-gray-200 dark:border-gray-700">
                                                                            {Object.entries(rounds).map(([roundNumber, vendors]) => (
                                                                                <div key={roundNumber} className="border-b last:border-b-0 dark:border-gray-700">
                                                                                    <NodeHeader
                                                                                        id={`${port}_${tourCode}_${cycleId}_${roundNumber}`}
                                                                                        title={`Round ${roundNumber}`}
                                                                                        count={`${Object.keys(vendors).length} Vendors`}
                                                                                        type="round"
                                                                                        onClick={() => toggleNode(`${port}_${tourCode}_${cycleId}_${roundNumber}`)}
                                                                                    />

                                                                                    {expandedNodes.has(`${port}_${tourCode}_${cycleId}_${roundNumber}`) && (
                                                                                        <div className="ml-4 space-y-4 p-4">
                                                                                            {Object.entries(vendors).map(([vendor, bidding]) => (
                                                                                                <div key={vendor} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
                                                                                                    <div className="font-medium mb-2 flex items-center justify-between">
                                                                                                        <div className="flex items-center space-x-2">
                                                                                                            <span>{vendor}</span>
                                                                                                            <span className="bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200 px-2 py-0.5 rounded-full text-xs">
                                                                                                                {bidding.tiers.length} Tiers
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        {bidding.document && <DocumentDownloadButton document={bidding.document} />}
                                                                                                    </div>
                                                                                                    <PricingTable tiers={bidding.tiers} isAddon={false} bidding={bidding} />
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    )}

                                    {/* Addon Tours Section */}
                                    {Object.keys(addonTours).length > 0 && (
                                        <>
                                            {/* Addon Section Header */}
                                            <div className="border-b dark:border-gray-700 bg-orange-50 dark:bg-gray-800">
                                                <div className="p-3 font-semibold text-orange-800 dark:text-orange-300">
                                                    Additional Services
                                                </div>
                                            </div>

                                            {Object.entries(addonTours).map(([tourCode, tourData]) => (
                                                <div key={tourCode} className="border-b last:border-b-0 dark:border-gray-700">
                                                    <NodeHeader
                                                        id={`${port}_${tourCode}`}
                                                        title={tourCode}
                                                        count={`${Object.keys(tourData.cycles).length} Cycles`}
                                                        type="tour"
                                                        isAddon={true}
                                                        onClick={() => toggleNode(`${port}_${tourCode}`)}
                                                    />

                                                    {expandedNodes.has(`${port}_${tourCode}`) && (
                                                        <div className="ml-4 border-l-2 border-gray-200 dark:border-gray-700">
                                                            {Object.entries(tourData.cycles).map(([cycleId, rounds]) => (
                                                                <div key={cycleId} className="border-b last:border-b-0 dark:border-gray-700">
                                                                    <NodeHeader
                                                                        id={`${port}_${tourCode}_${cycleId}`}
                                                                        title={cycleId === 'default' ? 'Default Cycle' : `Cycle ${cycleId}`}
                                                                        count={`${Object.keys(rounds).length} Rounds`}
                                                                        type="cycle"
                                                                        onClick={() => toggleNode(`${port}_${tourCode}_${cycleId}`)}
                                                                    />

                                                                    {expandedNodes.has(`${port}_${tourCode}_${cycleId}`) && (
                                                                        <div className="ml-4 border-l-2 border-gray-200 dark:border-gray-700">
                                                                            {Object.entries(rounds).map(([roundNumber, vendors]) => (
                                                                                <div key={roundNumber} className="border-b last:border-b-0 dark:border-gray-700">
                                                                                    <NodeHeader
                                                                                        id={`${port}_${tourCode}_${cycleId}_${roundNumber}`}
                                                                                        title={`Round ${roundNumber}`}
                                                                                        count={`${Object.keys(vendors).length} Vendors`}
                                                                                        type="round"
                                                                                        onClick={() => toggleNode(`${port}_${tourCode}_${cycleId}_${roundNumber}`)}
                                                                                    />

                                                                                    {expandedNodes.has(`${port}_${tourCode}_${cycleId}_${roundNumber}`) && (
                                                                                        <div className="ml-4 space-y-4 p-4">
                                                                                            {Object.entries(vendors).map(([vendor, bidding]) => (
                                                                                                <div key={vendor} className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-4">
                                                                                                    <div className="font-medium mb-2 flex items-center justify-between">
                                                                                                        <div className="flex items-center space-x-2">
                                                                                                            <span>{vendor}</span>
                                                                                                            <span className="bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200 px-2 py-0.5 rounded-full text-xs">
                                                                                                                {bidding.tiers.length} Tiers
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        {bidding.document && <DocumentDownloadButton document={bidding.document} />}
                                                                                                    </div>
                                                                                                    <PricingTable tiers={bidding.tiers} isAddon={true} bidding={bidding} />
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    // Get unique vendor types for filter tabs
    const uniqueVendorTypes = useMemo(() => {
        const types = ['All', ...new Set(vendors.map(vendor => vendor.type || 'Regular'))];
        return types.filter(Boolean); // Remove any null/undefined values
    }, [vendors]);

    // Filter vendors for selection
    const filteredSelectionVendors = useMemo(() => {
        return vendors.filter(vendor => {
            const matchesSearch =
                !vendorSearchTerm ||
                vendor.name?.toLowerCase().includes(vendorSearchTerm.toLowerCase()) ||
                vendor.email?.toLowerCase().includes(vendorSearchTerm.toLowerCase());

            const matchesType = vendorFilterType === 'All' || (vendor.type || 'Regular') === vendorFilterType;

            return matchesSearch && matchesType;
        });
    }, [vendors, vendorSearchTerm, vendorFilterType]);

    // Group vendors by type for better organization
    const vendorsGroupedByType = useMemo(() => {
        const groupedVendors = {};

        filteredSelectionVendors.forEach(vendor => {
            const type = vendor.type || 'Regular';
            if (!groupedVendors[type]) {
                groupedVendors[type] = [];
            }
            groupedVendors[type].push(vendor);
        });

        // Sort types alphabetically
        return Object.entries(groupedVendors)
            .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
            .map(([type, vendors]) => ({
                type,
                vendors: vendors.sort((a, b) => a.name.localeCompare(b.name))
            }));
    }, [filteredSelectionVendors]);

    // Add a new Document Download component
    const DocumentDownloadButton = ({ document }) => {
        if (!document || !document.url) return null;

        return (
            <a
                href={document.url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                download
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                Download
            </a>
        );
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <h1 className="text-3xl font-bold mb-8 text-blue-900 dark:text-blue-100">Shore Excursion Administration</h1>

            <Tab.Group>
                <Tab.List className="flex p-1 space-x-1 bg-blue-100 dark:bg-blue-900 rounded-xl">
                    <Tab className={({ selected }) =>
                        classNames(
                            'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                            'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                            selected
                                ? 'bg-white dark:bg-gray-800 shadow text-blue-700 dark:text-blue-100'
                                : 'text-blue-700 dark:text-blue-300 hover:bg-white/[0.12] hover:text-blue-800'
                        )
                    }>
                        Vendor Assignment
                    </Tab>
                    <Tab className={({ selected }) =>
                        classNames(
                            'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                            'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                            selected
                                ? 'bg-white dark:bg-gray-800 shadow text-blue-700 dark:text-blue-100'
                                : 'text-blue-700 dark:text-blue-300 hover:bg-white/[0.12] hover:text-blue-800'
                        )
                    }>
                        Bidding Management
                    </Tab>
                    <Tab className={({ selected }) =>
                        classNames(
                            'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                            'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                            selected
                                ? 'bg-white dark:bg-gray-800 shadow text-blue-700 dark:text-blue-100'
                                : 'text-blue-700 dark:text-blue-300 hover:bg-white/[0.12] hover:text-blue-800'
                        )
                    }>
                        View Biddings
                    </Tab>
                    <Tab className={({ selected }) =>
                        classNames(
                            'w-full py-2.5 text-sm leading-5 font-medium rounded-lg',
                            'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
                            selected
                                ? 'bg-white dark:bg-gray-800 shadow text-blue-700 dark:text-blue-100'
                                : 'text-blue-700 dark:text-blue-300 hover:bg-white/[0.12] hover:text-blue-800'
                        )
                    }>
                        Tour Management
                    </Tab>
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {/* First Tab Content */}
                    <Tab.Panel className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div>
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Select Vendors
                                    </h3>
                                    <div className="flex items-center space-x-4">
                                        <label className="flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-300 cursor-pointer">
                                            <input
                                                type="checkbox"
                                                checked={selectedVendors.length === vendors.length}
                                                onChange={handleSelectAllVendors}
                                                className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                            />
                                            <span>Select All</span>
                                        </label>
                                        <button
                                            onClick={clearVendors}
                                            className="px-2 py-1 text-sm text-gray-600 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-400 transition-colors"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>

                                {/* Vendors Search and Filtering */}
                                <div className="mb-4 space-y-3">
                                    <div className="flex flex-col sm:flex-row sm:space-x-3 space-y-2 sm:space-y-0">
                                        {/* Search Input */}
                                        <div className="relative flex-grow">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Search vendors by name or email..."
                                                value={vendorSearchTerm}
                                                onChange={(e) => setVendorSearchTerm(e.target.value)}
                                                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            />
                                        </div>

                                        {/* Reset Button */}
                                        {(vendorSearchTerm || vendorFilterType !== 'All') && (
                                            <button
                                                onClick={() => {
                                                    setVendorSearchTerm('');
                                                    setVendorFilterType('All');
                                                }}
                                                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600"
                                            >
                                                <svg className="-ml-0.5 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                                Reset Filters
                                            </button>
                                        )}
                                    </div>

                                    {/* Filter Tabs */}
                                    <div className="flex flex-col sm:flex-row sm:justify-between space-y-2 sm:space-y-0 sm:space-x-4">
                                        {/* Type Filters */}
                                        <div className="flex items-center space-x-1 overflow-x-auto pb-1">
                                            <span className="text-xs font-medium text-gray-500 dark:text-gray-400 whitespace-nowrap">Type:</span>
                                            {uniqueVendorTypes.map(type => (
                                                <button
                                                    key={type}
                                                    onClick={() => setVendorFilterType(type)}
                                                    className={`px-2 py-1 text-xs font-medium rounded-md whitespace-nowrap ${vendorFilterType === type
                                                        ? 'bg-blue-600 text-white dark:bg-blue-700'
                                                        : 'bg-white text-gray-700 hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700'
                                                        }`}
                                                >
                                                    {type}
                                                </button>
                                            ))}
                                        </div>

                                        {/* Grouping Toggle */}
                                        <div className="flex items-center space-x-2">
                                            <span className="text-xs font-medium text-gray-500 dark:text-gray-400 whitespace-nowrap">Group by type:</span>
                                            <button
                                                onClick={() => setVendorGroupByType(!vendorGroupByType)}
                                                className={`relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${vendorGroupByType ? 'bg-blue-600 dark:bg-blue-700' : 'bg-gray-200 dark:bg-gray-700'
                                                    }`}
                                            >
                                                <span
                                                    className={`pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${vendorGroupByType ? 'translate-x-5' : 'translate-x-0'
                                                        }`}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="relative">
                                    {vendorsLoading ? (
                                        <div className="flex justify-center items-center h-60">
                                            <LoadingSpinner />
                                        </div>
                                    ) : (
                                        <div className="max-h-96 overflow-y-auto border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 shadow-inner">
                                            {filteredSelectionVendors.length === 0 ? (
                                                <div className="flex flex-col items-center justify-center p-6 text-center">
                                                    <svg className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                                                    </svg>
                                                    <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">No vendors found</h3>
                                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                                        Try adjusting your search filters
                                                    </p>
                                                </div>
                                            ) : vendorGroupByType ? (
                                                // Vendors grouped by type
                                                <div>
                                                    {vendorsGroupedByType.map(({ type, vendors }) => (
                                                        <div key={type} className="mb-2">
                                                            <div className="sticky top-0 z-10 bg-gray-100 dark:bg-gray-700 px-4 py-2 font-medium text-sm text-gray-800 dark:text-gray-200 border-b border-gray-300 dark:border-gray-600">
                                                                {type}
                                                                <span className="ml-2 text-xs text-gray-500 dark:text-gray-400">
                                                                    ({vendors.length} {vendors.length === 1 ? 'vendor' : 'vendors'})
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {vendors.map(vendor => (
                                                                    <label
                                                                        key={vendor.id}
                                                                        className="flex items-start space-x-3 p-3 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer border-b border-gray-200 dark:border-gray-700"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedVendors.includes(vendor.email)}
                                                                            onChange={() => handleVendorSelection(vendor)}
                                                                            className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500 mt-0.5"
                                                                        />
                                                                        <div className="flex flex-col">
                                                                            <span className="text-gray-700 dark:text-gray-300 font-medium">
                                                                                {vendor.name}
                                                                            </span>
                                                                            <div className="flex items-center space-x-2 mt-1">
                                                                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                                                                                    {vendor.email}
                                                                                </span>
                                                                                {vendor.type && (
                                                                                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
                                                                                        {vendor.type}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                // Flat list of vendors
                                                <div>
                                                    {filteredSelectionVendors.map(vendor => (
                                                        <label
                                                            key={vendor.id}
                                                            className="flex items-start space-x-3 p-3 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer border-b border-gray-200 dark:border-gray-700"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedVendors.includes(vendor.email)}
                                                                onChange={() => handleVendorSelection(vendor)}
                                                                className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500 mt-0.5"
                                                            />
                                                            <div className="flex flex-col">
                                                                <span className="text-gray-700 dark:text-gray-300 font-medium">
                                                                    {vendor.name}
                                                                </span>
                                                                <div className="flex items-center space-x-2 mt-1">
                                                                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                                                                        {vendor.email}
                                                                    </span>
                                                                    {vendor.type && (
                                                                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200">
                                                                            {vendor.type}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </label>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div>
                                <div className="flex justify-between items-center mb-4">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Select Tours
                                    </h3>
                                    <div className="flex items-center space-x-4">
                                        <label className="flex items-center space-x-2 text-sm text-gray-600 dark:text-gray-300 cursor-pointer">
                                            <input
                                                type="checkbox"
                                                checked={selectedTours.length === tours.length}
                                                onChange={handleSelectAllTours}
                                                className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                            />
                                            <span>Select All</span>
                                        </label>
                                        <button
                                            onClick={clearTours}
                                            className="px-2 py-1 text-sm text-gray-600 dark:text-gray-300 hover:text-red-600 dark:hover:text-red-400 transition-colors"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>

                                {/* Tours Search and Filtering */}
                                <div className="mb-4 space-y-3">
                                    <div className="flex flex-col sm:flex-row sm:space-x-3 space-y-2 sm:space-y-0">
                                        {/* Search Input */}
                                        <div className="relative flex-grow">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                                </svg>
                                            </div>
                                            <input
                                                type="text"
                                                placeholder="Search tours by name or code..."
                                                value={tourSearchTerm}
                                                onChange={(e) => setTourSearchTerm(e.target.value)}
                                                className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                            />
                                        </div>

                                        {/* Reset Button */}
                                        {(tourSearchTerm || tourFilterPort !== 'All' || tourFilterType !== 'All') && (
                                            <button
                                                onClick={() => {
                                                    setTourSearchTerm('');
                                                    setTourFilterPort('All');
                                                    setTourFilterType('All');
                                                }}
                                                className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600 dark:hover:bg-gray-600"
                                            >
                                                <svg className="-ml-0.5 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                                Reset Filters
                                            </button>
                                        )}
                                    </div>

                                    {/* Filter Tabs */}
                                    <div className="flex flex-col sm:flex-row sm:justify-between space-y-2 sm:space-y-0 sm:space-x-4">
                                        {/* Port Filters */}
                                        <div className="flex items-center space-x-1 overflow-x-auto pb-1">
                                            <span className="text-xs font-medium text-gray-500 dark:text-gray-400 whitespace-nowrap">Port:</span>
                                            {uniquePorts.map(port => (
                                                <button
                                                    key={port}
                                                    onClick={() => setTourFilterPort(port)}
                                                    className={`px-2 py-1 text-xs font-medium rounded-md whitespace-nowrap ${tourFilterPort === port
                                                        ? 'bg-blue-600 text-white dark:bg-blue-700'
                                                        : 'bg-white text-gray-700 hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700'
                                                        }`}
                                                >
                                                    {port}
                                                </button>
                                            ))}
                                        </div>

                                        {/* Type Filters */}
                                        <div className="flex items-center space-x-1 overflow-x-auto pb-1">
                                            <span className="text-xs font-medium text-gray-500 dark:text-gray-400 whitespace-nowrap">Type:</span>
                                            {uniqueTourTypes.map(type => (
                                                <button
                                                    key={type}
                                                    onClick={() => setTourFilterType(type)}
                                                    className={`px-2 py-1 text-xs font-medium rounded-md whitespace-nowrap ${tourFilterType === type
                                                        ? 'bg-blue-600 text-white dark:bg-blue-700'
                                                        : 'bg-white text-gray-700 hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700'
                                                        }`}
                                                >
                                                    {type === 'Normal' ? 'Normal Tour' : type === 'Addon' ? 'Additional Service' : type}
                                                </button>
                                            ))}
                                        </div>

                                        {/* Grouping Toggle */}
                                        <div className="flex items-center space-x-2">
                                            <span className="text-xs font-medium text-gray-500 dark:text-gray-400 whitespace-nowrap">Group by port:</span>
                                            <button
                                                onClick={() => setTourGroupByPort(!tourGroupByPort)}
                                                className={`relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${tourGroupByPort ? 'bg-blue-600 dark:bg-blue-700' : 'bg-gray-200 dark:bg-gray-700'
                                                    }`}
                                            >
                                                <span
                                                    className={`pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${tourGroupByPort ? 'translate-x-5' : 'translate-x-0'
                                                        }`}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="relative">
                                    {toursLoading ? (
                                        <div className="flex justify-center items-center h-60">
                                            <LoadingSpinner />
                                        </div>
                                    ) : (
                                        <div className="max-h-96 overflow-y-auto border border-gray-300 dark:border-gray-600 rounded-lg bg-white dark:bg-gray-800 shadow-inner">
                                            {filteredSelectionTours.length === 0 ? (
                                                <div className="flex flex-col items-center justify-center p-6 text-center">
                                                    <svg className="h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                                                    </svg>
                                                    <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">No tours found</h3>
                                                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                                        Try adjusting your search filters
                                                    </p>
                                                </div>
                                            ) : tourGroupByPort ? (
                                                // Tours grouped by port
                                                <div>
                                                    {toursGroupedByPort.map(({ port, tours }) => (
                                                        <div key={port} className="mb-2">
                                                            <div className="sticky top-0 z-10 bg-gray-100 dark:bg-gray-700 px-4 py-2 font-medium text-sm text-gray-800 dark:text-gray-200 border-b border-gray-300 dark:border-gray-600">
                                                                {port}
                                                                <span className="ml-2 text-xs text-gray-500 dark:text-gray-400">
                                                                    ({tours.length} {tours.length === 1 ? 'tour' : 'tours'})
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {tours.map(tour => (
                                                                    <label
                                                                        key={tour.id}
                                                                        className="flex items-start space-x-3 p-3 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer border-b border-gray-200 dark:border-gray-700"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={selectedTours.includes(tour.id)}
                                                                            onChange={() => handleTourSelection(tour.id)}
                                                                            className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500 mt-0.5"
                                                                        />
                                                                        <div className="flex flex-col">
                                                                            <span className="text-gray-700 dark:text-gray-300 font-medium">
                                                                                {tour.name}
                                                                            </span>
                                                                            <div className="flex items-center space-x-2 mt-1">
                                                                                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                                                                                    {tour.code}
                                                                                </span>
                                                                                {tour.type && (
                                                                                    <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${tour.type === 'Addon'
                                                                                        ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200'
                                                                                        : 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                                                                        }`}>
                                                                                        {tour.type === 'Addon' ? 'Additional Service' : 'Normal Tour'}
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                // Flat list of tours
                                                <div>
                                                    {filteredSelectionTours.map(tour => (
                                                        <label
                                                            key={tour.id}
                                                            className="flex items-start space-x-3 p-3 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer border-b border-gray-200 dark:border-gray-700"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedTours.includes(tour.id)}
                                                                onChange={() => handleTourSelection(tour.id)}
                                                                className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500 mt-0.5"
                                                            />
                                                            <div className="flex flex-col">
                                                                <span className="text-gray-700 dark:text-gray-300 font-medium">
                                                                    {tour.name}
                                                                </span>
                                                                <div className="flex items-center space-x-2 mt-1">
                                                                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200">
                                                                        {tour.port}
                                                                    </span>
                                                                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                                                                        {tour.code}
                                                                    </span>
                                                                    {tour.type && (
                                                                        <span className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium ${tour.type === 'Addon'
                                                                            ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200'
                                                                            : 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                                                            }`}>
                                                                            {tour.type === 'Addon' ? 'Additional Service' : 'Normal Tour'}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </label>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="mt-6 space-y-2">
                            <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg">
                                <h4 className="font-semibold text-gray-900 dark:text-white mb-2">Selected Items:</h4>
                                <div className="space-y-2">
                                    <div>
                                        <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Vendors ({selectedVendors.length}):</h5>
                                        <div className="flex flex-wrap gap-1">
                                            {selectedVendors.length === 0 ? (
                                                <span className="text-sm text-gray-500 dark:text-gray-400">No vendors selected</span>
                                            ) : (
                                                selectedVendors.map(email => (
                                                    <span
                                                        key={email}
                                                        className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200"
                                                    >
                                                        {email}
                                                        <button
                                                            type="button"
                                                            onClick={() => handleVendorSelection({ email })}
                                                            className="ml-1.5 h-4 w-4 inline-flex items-center justify-center rounded-full text-blue-400 hover:text-blue-600 dark:text-blue-300 dark:hover:text-blue-100 focus:outline-none focus:text-blue-600"
                                                        >
                                                            <span className="sr-only">Remove {email}</span>
                                                            <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                            </svg>
                                                        </button>
                                                    </span>
                                                ))
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <h5 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">Tours ({selectedTours.length}):</h5>
                                        <div className="flex flex-wrap gap-1">
                                            {selectedTours.length === 0 ? (
                                                <span className="text-sm text-gray-500 dark:text-gray-400">No tours selected</span>
                                            ) : (
                                                selectedTours.map(tourId => {
                                                    const tour = tours.find(t => t.id === tourId);
                                                    return tour ? (
                                                        <span
                                                            key={tourId}
                                                            className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
                                                        >
                                                            {tour.name} ({tour.port})
                                                            <button
                                                                type="button"
                                                                onClick={() => handleTourSelection(tourId)}
                                                                className="ml-1.5 h-4 w-4 inline-flex items-center justify-center rounded-full text-green-400 hover:text-green-600 dark:text-green-300 dark:hover:text-green-100 focus:outline-none focus:text-green-600"
                                                            >
                                                                <span className="sr-only">Remove {tour.name}</span>
                                                                <svg className="h-3 w-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                                </svg>
                                                            </button>
                                                        </span>
                                                    ) : null;
                                                })
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <button
                                onClick={handleAssignVendors}
                                disabled={assignmentsLoading || selectedVendors.length === 0 || selectedTours.length === 0}
                                className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 disabled:opacity-50"
                            >
                                {assignmentsLoading ? (
                                    <div className="flex items-center justify-center space-x-2">
                                        <LoadingSpinner />
                                        <span>Assigning...</span>
                                    </div>
                                ) : (
                                    'Assign Vendors to Tours'
                                )}
                            </button>
                        </div>

                        {/* Adding Current Tour Assignments section here */}
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold mb-4 text-gray-900 dark:text-white">
                                Current Tour Assignments
                            </h3>
                            <div className="bg-gray-50 dark:bg-gray-700 rounded-lg overflow-hidden">
                                {assignedVendorsLoading ? (
                                    <div className="flex justify-center items-center h-40">
                                        <LoadingSpinner />
                                    </div>
                                ) : (
                                    Object.entries(
                                        assignments.reduce((acc, assignment) => {
                                            if (!acc[assignment.port]) {
                                                acc[assignment.port] = [];
                                            }
                                            acc[assignment.port].push(assignment);
                                            return acc;
                                        }, {})
                                    ).map(([port, portAssignments]) => (
                                        <div key={port} className="border-b border-gray-200 dark:border-gray-600 last:border-b-0">
                                            <button
                                                onClick={() => togglePortExpanded(port)}
                                                className="w-full bg-gray-100 dark:bg-gray-600 px-6 py-3 flex items-center justify-between text-lg font-semibold text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-500 transition-colors"
                                            >
                                                <div className="flex items-center space-x-2">
                                                    <span>{port}</span>
                                                    <span className="text-sm text-gray-500 dark:text-gray-400">
                                                        ({portAssignments.length} tours)
                                                    </span>
                                                </div>
                                                <svg
                                                    className={`w-5 h-5 transform transition-transform ${expandedPorts.has(port) ? 'rotate-180' : ''}`}
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                                </svg>
                                            </button>
                                            {expandedPorts.has(port) && (
                                                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
                                                    <thead className="bg-gray-50 dark:bg-gray-700">
                                                        <tr>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                Tour Code
                                                            </th>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                Tour Name
                                                            </th>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                Assigned Vendors
                                                            </th>
                                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                                                Actions
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                                                        {portAssignments.map((assignment) => (
                                                            <>
                                                                <tr key={assignment.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                                                        {assignment.code}
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                                                        {assignment.tourName}
                                                                    </td>
                                                                    <td className="px-6 py-4 text-sm text-gray-900 dark:text-white">
                                                                        {assignment.assignedVendors.length} Vendors
                                                                    </td>
                                                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                                                                        <button
                                                                            onClick={() => toggleExpanded(assignment.id)}
                                                                            className="text-blue-600 hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
                                                                        >
                                                                            <div className="flex items-center space-x-2">
                                                                                <span>{expandedAssignments.has(assignment.id) ? 'Hide' : 'Show'} Details</span>
                                                                                <svg
                                                                                    className={`w-5 h-5 transform transition-transform ${expandedAssignments.has(assignment.id) ? 'rotate-180' : ''}`}
                                                                                    fill="none"
                                                                                    stroke="currentColor"
                                                                                    viewBox="0 0 24 24"
                                                                                >
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                                                                </svg>
                                                                            </div>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                {expandedAssignments.has(assignment.id) && (
                                                                    <tr>
                                                                        <td colSpan="4" className="px-6 py-4 bg-gray-50 dark:bg-gray-700">
                                                                            <div className="space-y-2">
                                                                                {assignment.assignedVendors.map((vendor) => (
                                                                                    <div
                                                                                        key={vendor.id}
                                                                                        className="flex items-center justify-between p-3 bg-white dark:bg-gray-600 rounded-lg shadow-sm"
                                                                                    >
                                                                                        <div className="flex items-center space-x-4">
                                                                                            <span className="text-gray-900 dark:text-white">
                                                                                                {vendors.find(v => v.email === vendor.email)?.name || vendor.email}
                                                                                            </span>
                                                                                            {vendor.submitted && (
                                                                                                <span className="px-2 py-1 text-xs font-medium bg-green-100 text-green-800 rounded-full">
                                                                                                    Submitted
                                                                                                </span>
                                                                                            )}
                                                                                        </div>
                                                                                        <div className="flex items-center space-x-2">
                                                                                            <button
                                                                                                onClick={() => handleRemoveVendorFromAll(vendor)}
                                                                                                disabled={vendor.submitted || bulkDeletingVendor === vendor.email}
                                                                                                className={`p-1 rounded-md text-sm ${vendor.submitted || bulkDeletingVendor === vendor.email
                                                                                                    ? 'text-gray-400 cursor-not-allowed'
                                                                                                    : 'text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300 hover:bg-red-50 dark:hover:bg-red-900/20'
                                                                                                    }`}
                                                                                                title={vendor.submitted ? 'Cannot remove vendor who has submitted bids' : 'Remove from all tours'}
                                                                                            >
                                                                                                {bulkDeletingVendor === vendor.email ? (
                                                                                                    <div className="flex items-center space-x-1">
                                                                                                        <svg className="w-4 h-4 animate-spin" viewBox="0 0 24 24">
                                                                                                            <circle
                                                                                                                className="opacity-25"
                                                                                                                cx="12"
                                                                                                                cy="12"
                                                                                                                r="10"
                                                                                                                stroke="currentColor"
                                                                                                                strokeWidth="4"
                                                                                                                fill="none"
                                                                                                            />
                                                                                                            <path
                                                                                                                className="opacity-75"
                                                                                                                fill="currentColor"
                                                                                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                                                        </svg>
                                                                                                        <span>Removing...</span>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div className="flex items-center space-x-1">
                                                                                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                                        </svg>
                                                                                                        <span>Remove All</span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </button>
                                                                                            <button
                                                                                                onClick={() => handleRemoveVendor(assignment.id, vendor)}
                                                                                                disabled={vendor.submitted || deletingVendors.has(vendor.email)}
                                                                                                className={`p-1 rounded-full ${vendor.submitted || deletingVendors.has(vendor.email)
                                                                                                    ? 'text-gray-400 cursor-not-allowed'
                                                                                                    : 'text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300 hover:bg-red-50 dark:hover:bg-red-900/20'
                                                                                                    }`}
                                                                                                title={vendor.submitted ? 'Cannot remove vendor who has submitted bids' : 'Remove from this tour'}
                                                                                            >
                                                                                                {deletingVendors.has(vendor.email) ? (
                                                                                                    <svg className="w-5 h-5 animate-spin" viewBox="0 0 24 24">
                                                                                                        <circle
                                                                                                            className="opacity-25"
                                                                                                            cx="12"
                                                                                                            cy="12"
                                                                                                            r="10"
                                                                                                            stroke="currentColor"
                                                                                                            strokeWidth="4"
                                                                                                            fill="none"
                                                                                                        />
                                                                                                        <path
                                                                                                            className="opacity-75"
                                                                                                            fill="currentColor"
                                                                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                                                                        />
                                                                                                    </svg>
                                                                                                ) : (
                                                                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                                                                    </svg>
                                                                                                )}
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </Tab.Panel>

                    {/* Second Tab Content - Bidding Management */}
                    <BiddingManagementPanel />

                    {/* Third Tab Content */}
                    <Tab.Panel className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                        <div className="space-y-6">
                            <div className="bg-blue-50 dark:bg-blue-900 p-4 rounded-lg">
                                <h3 className="text-lg font-semibold text-blue-900 dark:text-blue-100 mb-2">
                                    Submitted Biddings
                                </h3>
                                {!submittedBiddingsLoading && submittedBiddings.length > 0 && (
                                    <span className="bg-blue-50 text-blue-700 dark:bg-blue-900 dark:text-blue-200 text-xs font-medium px-2.5 py-1 rounded-full">
                                        {submittedBiddings.length} Total Submissions
                                    </span>
                                )}
                            </div>

                            {submittedBiddingsLoading ? (
                                <div className="flex justify-center items-center h-40">
                                    <LoadingSpinner />
                                </div>
                            ) : (
                                <BiddingHierarchyView biddings={submittedBiddings} />
                            )}
                        </div>
                    </Tab.Panel>

                    {/* Fourth Tab Content */}
                    <Tab.Panel className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg">
                        <div className="space-y-6">
                            {/* Add Tour Form */}
                            <div className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                                    Add New Tour
                                </h3>
                                <form onSubmit={handleAddTour} className="space-y-4">
                                    <div>
                                        <label htmlFor="title" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Tour Name
                                        </label>
                                        <input
                                            type="text"
                                            id="title"
                                            name="title"
                                            value={newTour.title}
                                            onChange={(e) => setNewTour({ ...newTour, title: e.target.value })}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="port" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Port
                                        </label>
                                        <select
                                            id="port"
                                            name="port"
                                            value={newTour.port}
                                            onChange={(e) => setNewTour({ ...newTour, port: e.target.value })}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                            required
                                        >
                                            <option value="Jeddah">Jeddah</option>
                                            <option value="Dammam">Dammam</option>
                                            <option value="Yanbu">Yanbu</option>
                                            <option value="Alwajh">Alwajh</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label htmlFor="code" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Tour Code
                                        </label>
                                        <input
                                            type="text"
                                            id="code"
                                            name="code"
                                            value={newTour.code}
                                            onChange={(e) => setNewTour({ ...newTour, code: e.target.value })}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="type" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                            Tour Type
                                        </label>
                                        <select
                                            id="type"
                                            name="type"
                                            value={newTour.type}
                                            onChange={(e) => setNewTour({ ...newTour, type: e.target.value })}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:text-white"
                                            required
                                        >
                                            <option value="Normal">Normal Tour</option>
                                            <option value="Addon">Additional Service</option>
                                        </select>
                                    </div>
                                    {newTour.type === 'Normal' && (
                                        <div>
                                            <label htmlFor="document" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                                Tour Document
                                            </label>
                                            <input
                                                type="file"
                                                id="document"
                                                name="document"
                                                onChange={handleFileChange}
                                                className="mt-1 block w-full text-sm text-gray-500 dark:text-gray-300
                                                    file:mr-4 file:py-2 file:px-4
                                                    file:rounded-md file:border-0
                                                    file:text-sm file:font-semibold
                                                    file:bg-blue-50 file:text-blue-700
                                                    hover:file:bg-blue-100
                                                    dark:file:bg-gray-600 dark:file:text-gray-200"
                                            />
                                        </div>
                                    )}
                                    <button
                                        type="submit"
                                        disabled={isAddingTour}
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                                    >
                                        {isAddingTour ? (
                                            <div className="flex items-center space-x-2">
                                                <LoadingSpinner />
                                                <span>Adding Tour...</span>
                                            </div>
                                        ) : (
                                            'Add Tour'
                                        )}
                                    </button>
                                </form>
                            </div>

                            {/* Existing Tours List */}
                            <div className="bg-gray-50 dark:bg-gray-700 p-6 rounded-lg">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-4">
                                    Existing Tours
                                </h3>

                                {/* Search and Filter Controls */}
                                <div className="mb-6 flex flex-col md:flex-row justify-between gap-4">
                                    <div className="relative w-full md:w-1/3">
                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Search tours..."
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value);
                                                setCurrentPage(1); // Reset to first page on search
                                            }}
                                            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                                        />
                                    </div>

                                    <div className="flex flex-wrap gap-2">
                                        <div className="flex space-x-2 overflow-x-auto pb-2 sm:pb-0">
                                            {uniquePorts.map(port => (
                                                <button
                                                    key={port}
                                                    onClick={() => {
                                                        setFilterPort(port);
                                                        setCurrentPage(1); // Reset to first page on filter change
                                                    }}
                                                    className={`px-3 py-2 text-sm font-medium rounded-md whitespace-nowrap ${filterPort === port
                                                        ? 'bg-blue-600 text-white dark:bg-blue-700'
                                                        : 'bg-white text-gray-700 hover:bg-gray-50 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700'
                                                        }`}
                                                >
                                                    {port}
                                                </button>
                                            ))}
                                        </div>

                                        {(searchTerm || filterPort !== 'All') && (
                                            <button
                                                onClick={() => {
                                                    setSearchTerm('');
                                                    setFilterPort('All');
                                                    setCurrentPage(1);
                                                }}
                                                className="px-3 py-2 text-sm font-medium rounded-md whitespace-nowrap bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 flex items-center"
                                            >
                                                <svg className="mr-1.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                </svg>
                                                Reset Filters
                                            </button>
                                        )}
                                    </div>
                                </div>

                                {toursLoading ? (
                                    <div className="flex justify-center items-center h-64">
                                        <LoadingSpinner />
                                    </div>
                                ) : (
                                    <>
                                        {sortedTours.length === 0 ? (
                                            <div className="text-center py-10">
                                                <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
                                                </svg>
                                                <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-white">No tours found</h3>
                                                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                                    {searchTerm || filterPort !== 'All'
                                                        ? 'Try adjusting your search or filter to find what you\'re looking for.'
                                                        : 'Get started by adding a new tour.'}
                                                </p>
                                            </div>
                                        ) : (
                                            <>
                                                {/* Card Grid Layout */}
                                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                                                    {currentTours.map((tour) => (
                                                        <div key={tour.id} className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg transition-all duration-200 hover:shadow-md border border-gray-200 dark:border-gray-700">
                                                            <div className="px-4 py-5 sm:p-6">
                                                                <div className="flex items-center justify-between">
                                                                    <div className="flex items-center space-x-2">
                                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200">
                                                                            {tour.code}
                                                                        </span>
                                                                        {tour.type && (
                                                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${tour.type === 'Addon'
                                                                                ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200'
                                                                                : 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                                                                                }`}>
                                                                                {tour.type === 'Addon' ? 'Additional Service' : 'Normal Tour'}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200">
                                                                            {tour.port}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <h3 className="mt-3 text-lg font-medium text-gray-900 dark:text-white truncate" title={tour.name}>
                                                                    {tour.name}
                                                                </h3>
                                                                <div className="mt-4 flex justify-end">
                                                                    <button
                                                                        onClick={() => handleEditTour(tour)}
                                                                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-700 dark:hover:bg-blue-600"
                                                                    >
                                                                        <svg className="mr-1.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                        </svg>
                                                                        Edit
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                                {/* Pagination */}
                                                {totalPages > 1 && <Pagination />}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>

            {/* Add Confirmation Modals */}
            {showConfirmation && <ConfirmationModal />}
            {removeAllConfirmation.show && <RemoveAllConfirmationModal />}
            {editingTour && <EditTourModal />}
        </div>
    );
}

export default ShoreExcursionAdmin; 